.input {
  width: 100%;
  margin: 0;
  padding-top: 6px;
  padding-bottom: 6px;
  padding-left: 0;
  padding-right: 0;
  box-shadow: none;
  font-size: 19px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.4210526315789473;
  letter-spacing: -0.3166666924953461px;
  text-align: left;
  color: $input-font-color;
  border: none;
  border-bottom: 2px solid $input-border-color;
  background-color: $input-color;
  appearance: none;
  border-radius: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  &::placeholder { color: inherit; }
  &:focus { outline: 1px dotted $input-focus-border; }
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    display: none;
  }
  -moz-appearance: textfield;

  &::-ms-clear {
      display: none;
  }

  &--password {
    position: relative;

  }

  &_show {
    position: absolute;
    border: none;
    top: 0;
    right: 0;
  }
}