$curve: cubic-bezier(0.650, 0.000, 0.450, 1.000);

.checkmark__grad {
  width: 48px;
  height: 48px;
}

.checkmark__circle {
  stroke-dasharray: 166;
  stroke-dashoffset: 166;
  stroke-width: 2;
  stroke-miterlimit: 10;
  stroke: url(#blueGrad);
  &--static {
    stroke-width: 94%;
    fill: url(#blueGrad);
  }
  &--animated {
    fill:none;
    animation: stroke .6s $curve forwards, grad .4s ease-in-out .4s forwards;
  }
}

.checkmark {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 20px;
  box-shadow: inset 0px 0px 0px url(#blurGrad);

  &--animated {
    animation: fill .4s ease-in-out .4s forwards, scale .3s ease-in-out .9s both;
  }

}

.checkmark__check {
  stroke-width: 2;
  stroke: #fff;
  stroke-miterlimit: 10;
  stroke-linejoin:round;
  stroke-linecap:round;
  transform-origin: 50% 50%;
  stroke-dashoffset: 48;
  &--static {
    stroke-dashoffset: 0;
  }
  &--animated {
    stroke-dasharray: 48;
    animation: stroke .3s $curve .8s forwards;
  }
}

@keyframes stroke {
  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes scale {
  0%, 100% {
    transform: none;
  }
  50% {
    transform: scale3d(1.1, 1.1, 1);
  }
}

@keyframes grad {
   0% {
     stroke-width: 2;
   }
   100% {
     stroke-width: 94%;
   }
}