.table-list {
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  font-family: $font-family-body;
  &__row-header,
  &__row-content {
    line-height: 1.3;
    @media #{$media-md-up} {
      line-height: initial;
    }
  }
  &__row-header {
    position: relative;
    width: 100%;
    padding-top: 20px;
    padding-bottom: 5px;
    font-weight: bold;
    @media #{$media-md-up} {
      width: 170px;
      padding-bottom: 20px;
      border-bottom: 1px solid $table-list-border-color;
    }
    &--indented {
      padding-top: 10px;
      padding-left: 20px;
      border-left: 4px solid $table-list-border-color;
      padding-bottom: 10px;
      & + .table-list__row-content {
        padding-top: 10px;
        @media #{$media-sm-down} {
          padding-top: 10px;
        }
      }
    }
    &--bordered {
      border-bottom: 1px solid $table-list-border-color;
    }
  }
  &__row-content {
    position: relative;
    width: 100%;
    margin: 0;
    margin-left: auto;
    padding-top: 5px;
    padding-bottom: 20px;
    border-bottom: 1px solid $table-list-border-color;
    @media #{$media-md-up} {
      display: flex;
      width: calc(100% - 170px);
      padding-top: 20px;
      padding-left: 30px;
      justify-content: space-between;
    }
  }
  &__link {
    display: block;
    margin-top: 5px;
    @media #{$media-md-up} {
      width: 270px;
      margin-top: 0;
      flex-shrink: 0;
      text-align: right;
    }
  }
  &__helper {
    font-weight: 600;
    font-size: 13px;
    letter-spacing: -0.2px;
    line-height: .5;
  }
  &--simple {

    + .table-list {
      margin-top: 20px;
      padding-top: 20px;
    }
    .table-list__row-header {
      width: 50%;
      padding-bottom: 0;
      border-bottom: none;
      font-weight: 800;

      &:first-of-type {
        padding-top: 0;
      }
      &--weak {
        font-weight: $font-weight-body;
      }
    }
    .table-list__row-content {
      display: block;
      width: 50%;
      min-width: 0;
      padding-bottom: 0;
      text-align: right;
      border-bottom: none;
      word-break: break-word;
      @media #{$media-sm-down} {
        padding-top: 20px;
      }
      &:first-of-type {
        padding-top: 0;
      }
      &--strong {
        font-weight: $font-weight-bold;
      }
    }
  }
  &--inverted {
    .table-list__row-header {
      width: calc(100% - 100px);
      padding-top: 0;
      padding-bottom: 10px;
      font-weight: $font-weight-body;
      &:nth-last-of-type(2) {
        padding-bottom: 0;
      }
    }
    .table-list__row-content {
      width: 100px;
      padding-top: 0;
      padding-bottom: 10px;
      padding-left: 0;
      &:last-of-type {
        padding-bottom: 0;
      }
    }
  }
  &--summary {
    font-size: 19px;
    .table-list__summary-total {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      padding-top: 15px;
      margin-top: 15px;
      border-top: 1px solid $table-list-border-color;
      .table-list__row-header--indented {
        padding-top: 0;
        padding-bottom: 0;
        padding-left: 20px;
        border: none;
      }
      .table-list__row-header {
        &:nth-last-of-type(2) {
          padding-top: 20px;
        }
      }
      .table-list__row-content {
        &:nth-last-of-type(1) {
          padding-top: 20px;
        }
      }
    }
    .table-list__row-header {
      display: block;
      padding-top: 0;
      padding-bottom: 0;
      border-bottom: none;
      line-height: 1.42;
    }
    .table-list__row-content {
      display: block;
      padding-top: 0;
      padding-bottom: 0;
      text-align: right;
      border-bottom: none;
      line-height: 1.42;
    }
  }
  &--seperated {
    border-top: 1px solid $table-list-border-color;
  }
}