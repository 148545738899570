.breadcrumbs {
  padding-top: 16px;
  padding-bottom: 16px;
  background-color: $breadcrumbs-color;
  @media #{$media-md-up} {
    padding-top: 14px;
    padding-bottom: 14px;
  }
  &__list {
    display: flex;
    margin: 0;
    padding: 0;
    list-style: none;
    flex-wrap: wrap;
  }
  &__item {
    margin-top: 0;
    font-size: 14px;
    font-weight: 600;
    line-height: 1.8571428571428572;
    letter-spacing: -0.2333333px;
    color: $breadcrumbs-font-color;
    white-space: nowrap;
    &:after {
      content: "/";
      display: inline-block;
      padding-left: 10px;
      padding-right: 10px
    }
    &:first-child {
      padding-left: 0;
    }
    &:last-child:after {
      display: none;
    }
  }
  &__crumb {
    position: relative;
    display: inline-block;
    font-size: inherit;
    line-height: inherit;
    letter-spacing: inherit;
    font-weight: inherit;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
    &--current {
      color: inherit;
      &:hover {
        text-decoration: none;
      }
    }
  }
}