.tabnav {
  &__tabs {
    display: none;
    margin: 0;
    padding: 0;
    list-style: none;
    @media #{$media-md-up} {
      display: flex;
      justify-content: center;
    }
  }
  &__tab {
    flex-basis: 100%;
    display: flex;
    margin: 0;
    padding-top: 16px;
    padding-bottom: 16px;
    padding-left: 0;
    padding-right: 0;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    text-align: center;
    background: transparent;
    transition: background-color 250ms ease;
    &:hover,
    &:focus {
      text-decoration: underline;
      background-color: $tabnav-color-hover;
      background: linear-gradient(-180deg, $tabnav-color-hover-alt 50%, $tabnav-color-hover 100%);
    }
    &--active {
      padding-bottom: 8px;
      border-bottom: 8px solid $tabnav-active-color;
      &:hover,
      &:focus {
        text-decoration: none;
        background: none;
      }
      &:before {
        content: "Current Page: ";
        position: absolute;
        width: 1px;
        height: 1px;
        padding: 0;
        margin: -1px;
        overflow: hidden;
        clip: rect(0, 0, 0, 0);
        border: 0;
      }
    }
  }
  &__select {
    position: relative;
    height: 60px;
    width: 100%;
    &:after {
      content: " ";
      position: absolute;
      top: 50%;
      right: 20px;
      display: inline-block;
      padding: 6px;
      border: solid $tabnav-select-arrow;
      border-width: 0 2px 2px 0;
      transform: translateY(-64%) rotate(45deg);
    }
    @media #{$media-md-up} {
      display: none;
    }
  }
  &__select-node {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding-left: 20px;
    padding-right: 45px;
    appearance: none;
    border: none;
    border-radius: 0;
    background: none;
    color: $tabnav-font-color-select;
    line-height: normal;
    font-weight: inherit;
    &::-ms-expand {
      display: none;
    }
  }
  &--center {
    .tabnav__tab {
      @media #{$media-md-up} {
        flex-basis: 20%;
      }
    }
  }
}