.heading {
  margin: 0;
  padding-bottom: 0;
  padding-bottom: 10px;
  font-family: $font-family-heading;
  font-size: 28px;
  font-weight: 900;
  font-style: normal;
  font-stretch: normal;
  line-height: 38px;
  letter-spacing: -0.44px;
  text-align: center;
  color: $font-color-heading;
  @media #{$media-md-up} {
    font-size: 48px;
    line-height: 65px;
    letter-spacing: -1px;
  }
  &--secondary {
    font-size: 24px;
    line-height: 33px;
    letter-spacing: -0.5px;
    @media #{$media-md-up} {
      font-size: 32px;
      line-height: 46px;
      letter-spacing: -0.67px;
    }
  }
  &--tertiary {
    font-size: 21px;
    line-height: 30px;
    letter-spacing: -0.44px;
    text-align: inherit;
    @media #{$media-md-up} {
      font-size: 24px;
      line-height: 33px;
      letter-spacing: -0.5px;
    }
  }
  &--sm {
    font-size: 19px;
    line-height: 27px;
    letter-spacing: -0.4px;
    text-align: left;
    @media #{$media-md-up} {
      font-size: 21px;
      line-height: 30px;
      letter-spacing: -0.44px;
    }
  }
  &--lg {
    @media #{$media-md-up} {
      font-size: 64px;
      line-height: 85px;
    }
  }
  &--center {
    text-align: center;
  }
  &--left {
    text-align: left;
  }
  &--modal {
    padding-bottom: 30px;
  }
  &--md-left {
    @media #{$media-md-up} {
      text-align: left;
    }
  }
  &--ko {
    color: $font-color-heading-ko;
  }
  &--tooltip {
    line-height: 32px;
  }
}