$header-height-sm: 60px;
$search-transition-speed: 300ms;

.main-header {
  position: relative;
  z-index: 100;
  width: 100%;
  &__wrapper {
    position: relative;
    width: 100%;
    height: $header-height-sm;
    padding-top: 6px;
    padding-bottom: 6px;
    font-size: 19px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: -0.2955555617809296px;
    color: $header-font-color;
    background-color: $header-color;
    transition: height 200ms cubic-bezier(0.77,0.2,0.05,1.0), overflow 0ms ease;
    transition-delay: 200ms;
    z-index: 105;
    @media #{$nav-breakpoint-up} {
      height: auto;
      padding-top: 20px;
      padding-bottom: 18px;
    }
    .container {
      @media #{$nav-breakpoint-down} {
        padding-left: 10px;
        padding-right: 17px;
      }
    }
  }
  &__content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    @media #{$nav-breakpoint-up} {
      justify-content: flex-start;
    }
  }
  &__logo {
    display: block;
    height: 50px;
    img {
      height: 100%;
      width: auto;
    }
    @media #{$nav-breakpoint-up} {
      height: 82px;
    }
  }
  &__link {
    position: relative;
    display: block;
    padding-top: 10px;
    padding-bottom: 10px;
    color: inherit;
    text-decoration: none;
    @media #{$nav-breakpoint-up} {
      padding-top: 0;
      padding-bottom: 0;
      padding-left: 10px;
      padding-right: 10px;
      line-height: 40px;
    }
    @media #{$nav-breakpoint-alt-up} {
      padding-left: 17px;
      padding-right: 17px;
    }
    &:hover,
    &:focus {
      text-decoration: none;
    }
    &--stacked {
      font-size: 14px;
      line-height: 1;
      .icon {
        margin: 0;
      }
      .main-header__link-text {
        display: block;
        line-height: 1.7;
      }
    }
    &--hidden-mobile {
      display: none;
      @media #{$nav-breakpoint-up} {
        display: block;
      }
    }
  }
  &__link-text {
    position: relative;
    display: inline-block;
    &:before,
    &:after {
      position: absolute;
      content: '';
      display: block;
      bottom: 0;
      width: 0;
      height: 2px;
    }
    &:before {
      left: 0;
      transition: width 0s ease, background 300ms ease;
    }
    &:after {
      right: 0;
      background: $header-font-color;
      transition: width 300ms ease;
    }
  }
  &__nav-item--active {
    .main-header__link-text:before {
      width: 100%;
      background: $header-font-color;
    }
  }
  &__link:hover,
  &__link:focus {
    @media #{$nav-breakpoint-up} {
      .main-header__link-text:before {
        width: 100%;
        background: $header-font-color;
        transition: width 300ms ease;
      }
      .main-header__link-text:after {
        width: 100%;
        background: transparent;
        transition: all 0ms ease;
      }
    }
    // Touch devices shouldn't get hover states
    @media (hover: none) {
      .main-header__link-text:before {
        display: none;
      }
      .main-header__link-text:after {
        display: none;
      }
    }
  }
  &__menu {
    display: block;
    position: relative;
    color: inherit;
    font-size: 13px;
    text-decoration: none;
    text-transform: uppercase;
    user-select: none;
  }
  &__menu-bar {
    position: relative;
    display: block;
    width: 29px;
    height: 2px;
    margin-bottom: 8px;
    margin-left: auto;
    margin-right: auto;
    background-color: $header-menu-color;
    border-radius: 0;
    transform-origin: 21px 0px;
    transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
                background-color 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
                opacity 0.55s ease;
    &:nth-child(1) { transform-origin: center left; }
    &:nth-child(2) { transform-origin: 0 0; }
    &:nth-child(3) {
      transform-origin: center left;
      margin-bottom: 0;
    }
  }
  &__nav {
    position: absolute;
    top: 0;
    left: 0;
    height: 0;
    width: 100%;
    margin-top: $header-height-sm;
    background-color: $header-color;
    transition: height 400ms ease, overflow 0ms ease 400ms;
    order: 1;
    overflow: hidden;
    @media #{$nav-breakpoint-up} {
      position: relative;
      height: auto;
      width: auto;
      margin-top: 0;
      margin-left: auto;
      overflow: visible;
      order: inherit;
    }
    &--secondary {
      margin-left: 0;
    }
  }
  &__nav-list {
    position: relative;
    display: flex;
    margin: 0;
    padding-top: 23px;
    padding-bottom: 30px;
    padding-left: 0;
    padding-right: 0;
    flex-direction: column;
    text-align: center;
    list-style: none;
    @media #{$nav-breakpoint-up} {
      padding-top: 0;
      padding-bottom: 0;
      flex-direction: row;
      align-items: center;
    }
    &--mobile-search {
      padding-top: 84px;
      @media #{$nav-breakpoint-up} {
        padding-top: 0;
      }
    }
  }
  &__nav-item {
    position: relative;
    min-width: 180px;
    max-width: 100%;
    margin-top: 0;
    margin-left: auto;
    margin-right: auto;
    opacity: 0;
    transform: translateY(-20px);
    transition: opacity 200ms cubic-bezier(0.77,0.2,0.05,1.0),
      transform 200ms cubic-bezier(0.77,0.2,0.05,1.0);
    @for $i from 2 through 10 {
      &:nth-last-child(#{$i}) { transition-delay: #{(($i - 1) * 50)}ms; }
    }
    @media #{$nav-breakpoint-up} {
      height: 40px;
      min-width: 0;
      margin-left: 0;
      margin-right: 0;
      opacity: 1;
      transform: none;
      transition: none;
    }
    &--hidden-mobile {
      display: none;
      @media #{$nav-breakpoint-up} {
        display: block;
      }
    }
    &--bordered {
      @media #{$nav-breakpoint-up} {
        margin-left: 13px;
        margin-right: 30px;
        border-left: 1px solid $header-border-color;
        border-right: 1px solid $header-border-color;
      }
    }
    &--border-left {
      @media #{$nav-breakpoint-up} {
        margin-left: 17px;
        padding-left: 17px;
        border-left: 1px solid $header-border-color;
      }
    }
    &--border-right {
      @media #{$nav-breakpoint-up} {
        margin-right: 17px;
        padding-right: 17px;
        border-right: 1px solid $header-border-color;
      }
    }
    &--border-top {
      margin-top: 10px;
      padding-top: 10px;
      border-top: 1px solid $header-border-color;
      @media #{$nav-breakpoint-up} {
        margin-top: 0;
        padding-top: 0;
        border-top: none;
      }
    }
  }
  &__mobile-menu {
    display: grid;
    grid-auto-flow: column;
    grid-gap: 16px;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    font-size: 16px;
    @media #{$nav-breakpoint-up} {
      display: none;
    }
    &--comfortable {
      grid-gap: 30px;
    }
  }
  &__searchfield {
    position: absolute;
    top: $header-height-sm;
    left: 0;
    height: 0;
    width: 100%;
    margin: 0;
    padding: 0;
    border: none;
    z-index: 105;
    opacity: 0;
    transform: translateY(-20px);
    transition: opacity 200ms cubic-bezier(0.77,0.2,0.05,1.0) 150ms,
      transform 200ms cubic-bezier(0.77,0.2,0.05,1.0) 150ms, height 0ms ease 400ms;
    overflow: hidden;
    @media #{$nav-breakpoint-up} {
      top: 0;
      height: auto;
      padding-top: 28px;
      padding-bottom: 32px;
      background-color: $header-color-search;
      z-index: 101;
      opacity: 0;
      transform: translateY(0);
      transition: top $search-transition-speed ease;
      transition-delay: 0;
    }
  }
  &__form {
    position: relative;
    margin-top: 30px;
    @media #{$nav-breakpoint-up} {
      margin-top: 0;
      padding-left: 163px;
      padding-right: 163px;
    }
  }
  &__search-input {
    width: 100%;
    padding-top: 9px;
    padding-bottom: 9px;
    padding-right: 35px;
    background: none;
    box-shadow: none;
    text-align: left;
    color: $header-font-color-search;
    border: none;
    border-bottom: 1px solid $header-border-color;
    appearance: none;
    border-radius: 0;
    &:placeholder { color: $header-font-color-search; }
    &::placeholder { color: $header-font-color-search; opacity: 1; }
    &:focus { outline: 0; }
  }
  &__search-icon {
    position: relative;
    top: 0;
    right: 0;
    background-color: transparent;
    background-image: url(/images/global/icons/search-alt.svg);
    background-repeat: no-repeat;
    background-position: center center;
    background-color: transparent;
    background-size: contain;
    border: none;
    appearance: none;
    transition: transform $search-transition-speed ease,
      opacity $search-transition-speed ease;
    @media #{$nav-breakpoint-up} {
      display: block;
      width: 83px;
      height: 40px;
    }
  }
  &__open-search {
    position: absolute;
    opacity: 1;
  }
  &__search-button {
    position: absolute;
    top: 0;
    right: 0;
    width: 40px;
    height: 40px;
    padding: 0;
    background-color: transparent;
    border: none;
    appearance: none;
    @media #{$nav-breakpoint-up} {
      right: 164px;
    }
  }
  &--search-open {
    .main-header__search-icon {
      background-image: url(/images/global/icons/close.svg);
      background-size: 30px 30px;
    }
    .main-header__searchfield {
      top: 100%;
      opacity: 1;
    }
    .main-header__close-search {
      opacity: 1;
    }
    .main-header__open-search {
      opacity: 0;
      transform: translateY(100px);
    }
    .main-header__nav-list {
      &:before {
        content: " ";
        position: absolute;
        right: 140px;
        top: 0;
        width: 60px;
        height: 85px;
        background-color: $header-color-search;
        @media #{$nav-breakpoint-alt-up} {
          right: 155px;
        }
      }
    }
  }
}

.nav-open {
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  .main-header {
    &__menu-bar {
      opacity: 1;
      transform: rotate(45deg);
      &:nth-child(2) {
        opacity: 0;
        transform: rotate(0deg) scale(0.2, 0.2);
      }
      &:nth-child(3) { transform: rotate(-45deg); }
    }
    &__nav {
      height: calc(100vh - 84px);
      overflow: auto;
    }
    &__searchfield {
      height: 86px;
      opacity: 1;
      transform: translateY(0);
      transition: height 0ms ease, opacity 200ms cubic-bezier(0.77,0.2,0.05,1.0),
      transform 200ms cubic-bezier(0.77,0.2,0.05,1.0);
    }
    &__nav-item {
      opacity: 1;
      transform: translateY(0);
      transition-delay: 150ms;
      @for $i from 1 through 10 {
        &:nth-child(#{$i}) { transition-delay: #{(($i - 1) * 50)}ms; }
      }
    }
  }
}