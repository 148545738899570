table,
.table {
  position: relative;
  width: 100%;
  border-collapse: collapse;
  border: none;
  a {
    font-weight: 600;
  }
  &--alternate {
    tbody tr:nth-child(odd) {
      background-color: $table-alt-row-color;
    }
  }
  @media #{$media-sm-down} {
    table-layout: fixed;
    width: 100%;
  }

  &--compact {
    thead,
    tbody {
      @media #{$media-md-up} {
        font-size: 13px;
        line-height: 20px;
        td,
        th {
          height: initial;
          padding-top: 10px;
          padding-bottom: 10px;
        }
      }
    }
  }
}

.table__mobile-header {
  flex-grow: 1;
  text-align: left;
}

caption,
.table__title {
  position: relative;
  padding-left: 0;
  padding-right: 0;
  padding-bottom: 20px;
  font-family: $font-family-heading;
  font-size: 21px;
  font-weight: 900;
  font-style: normal;
  font-stretch: normal;
  line-height: 30px;
  letter-spacing: -0.44px;
  text-align: inherit;
  color: $font-color-heading;
  border-bottom: 1px solid $table-border-color;
  caption-side: top;

  @media #{$media-sm-down} {
    margin-left: 0;
    margin-right: 0;
  }
   .table__caption-link {
    float: right;
    font-family: $font-family-body;
    font-size: $font-size-alt;
    &--history-desktop {
      display: none;
      @media #{$media-md-up} {
        display: inline;
      }
    }
  }
}

thead {
  @media #{$media-sm-down} {
    display: none;
  }
}

tr,
.table__row {
  padding-top: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid $table-border-color;
  @media #{$media-md-up} {
    padding-top: 0;
    padding-bottom: 0;
  }
  @media #{$media-sm-down} {
    display: block;
  }
  &--unread {
    font-weight: 800;
  }
  &--selected {
    background-color: $table-active-row-color;
  }
  &--clean {
    border-bottom: none;
  }
  &:focus {
    outline: 1px dotted black;
  }
}

td,
th,
.table__header,
.table__cell {
  &:first-of-type {
    padding-left: 0;
  }
  @media #{$media-md-up} {
    padding-left: 10px;
    padding-right: 0;
    padding-top: 14px;
    padding-bottom: 14px;
    text-align: left;
  }
  &--left-to-center {
    @media #{$media-md-up} {
      text-align: center;
    }
  }
  &--right {
    text-align: right;
  }
  &--actions {
    a {
      + a {
        margin-left: 30px;
      }
    }
    @media #{$media-xs-only} {
      a {
        display: block;
        + a {
          margin-left: 0;
        }
      }
    }
  }
  &--details {
    @media #{$media-md-up} {
      display: none;
    }
  }
  &--amount {
    padding-left: 0;
    padding-right: 0;
  }
}

td,
.table__cell {
  vertical-align: top;
}

thead th,
.table__header {
  font-weight: 800;
  color: $font-color-heading;
  &--hidden-on-desktop {
    @media #{$media-md-up} {
      display: none;
    }
  }
}

tbody {
  tr,
  .table__row {
    @media #{$media-md-up} {
      &[data-expand] {
        display: none;
      }
    }
    @media #{$media-xs-only} {
      display: flex;
      flex-wrap: wrap;
      margin-left: 0;
      margin-right: 0;
      padding-left: 0;
      padding-right: 0;
    }
    @media #{$media-sm-only} {
      display: grid;
      grid-template-columns: 25% 25% 25% 25%;
    }
  }
  th {
    @media #{$media-md-down} {
      &[data-expand] {
        display: none;
      }
    }
    @media #{$media-md-up} {
      display: none;
      vertical-align: top;
    }
    @media #{$media-sm-only} {
      grid-column-start: 3;
      grid-column-end: 3;
      padding-left: 10px;
      &:first-child {
        grid-column-start: 1;
        grid-column-end: 2;
        grid-row-start: 1;
        grid-row-end: 4;
        border-left: none;
        padding-left: 0;
      }
      + td {
        grid-column-start: 4;
        grid-column-end: 4;
        margin-bottom: 10px;
        padding: 0;
        text-align: right;
        &:first-of-type {
          grid-column-start: 2;
          grid-column-end: 3;
          grid-row-start: 1;
          grid-row-end: 4;
          padding-right: 10px;
        }
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
    @media #{$media-xs-only} {
      display: table-cell;
      flex: 0 0 50%;
      + td {
        flex: 0 0 50%;
        margin-bottom: 10px;
        padding: 0;
        text-align: right;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
  td,
  .table__cell {
    font-size: inherit;
    vertical-align: top;
    .text-sm {
      display: block;
    }
    @media #{$media-sm-down} {
      &[data-expand] {
        display: none;
      }
    }
    &--empty,
    &--empty:first-child,
    &--empty:last-child {
      @media #{$media-sm-down} {
        display: none;
      }
    }

    &:last-child {
      padding-right: 0;
    }
  }
}

.table__row--unpaid {
  td {
    font-style: italic;
  }
  a {
    font-style: normal;
  }
}

.table__attachment-link {
  @media #{$media-md-up} {
    float: right;
    width: 320px;
    padding-right: 30px;
    line-height: 30px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.table__edit-link {
  margin-left: 10px;
}

.table__cta {
  @media #{$media-sm-down} {
    display: block;
  }

  tr {
    margin-left: 0;
    margin-right: 0;
    padding-top: 0;
    padding-bottom: 0;
    background-color: $table-active-row-color;
    border-bottom: none;
    @media #{$media-sm-down} {
      display: block;
    }
  }
  td {
    text-align: center;
    @media #{$media-md-up} {
      padding-top: 0;
      padding-bottom: 0;
    }
    @media #{$media-sm-down} {
      display: block;
    }
  }
  a {
    display: block;
    width: 100%;
    height: 100%;
    padding-top: 18px;
    padding-bottom: 17px;
  }
  &--simple {
    tr {
      background-color: #fff;
    }
  }
  &--mobile {
    tr {
      background-color: $color-white;
    }
    @media #{$media-md-up} {
      display: none;
    }
  }
  &--desktop {
    @media #{$media-sm-down} {
      display: none;
    }
  }
}

.table--clean {
  tr {
    border-bottom: none;
  }
  tbody {
    td,
    .table__cell {
      font-size: inherit;
    }
  }
  th,
  td,
  .table__header,
  .table__cell {
    &:first-child {
      @media #{$media-md-up} {
        padding-left: 0;
      }
    }
  }
}
.table--empty {
  background-color: $table-empty-color;
  td {
    padding-top: 20px;
    padding-bottom: 20px;
    text-align: center;
    font-size: inherit;
    @media #{$media-md-up} {
      padding-top: 50px;
      padding-bottom: 78px;
    }
  }
  tbody tr,
  tbody .table__row {
    display: table-row;
    border-bottom: none;
    &:first-child {
      border-top: none;
    }
  }
  tbody td,
  tbody td:first-child,
  tbody .table__cell,
  tbody .table__cell:first-child {
    display: table-cell;
    vertical-align: middle;
    text-align: center;
    @media #{$media-sm-down} {
      height: 114px;
    }
  }
  &.table--empty--inverted {
    background-color: $table-empty-inverted-color;
  }
}

.table--simple {
  thead {
    display: table-header-group;
  }
  th {
    display: table-cell;
  }
  tr {
    display: table-row;
  }
  td {
    display: table-cell;
    padding-top: 10px;
    padding-bottom: 10px;
  }
}

.table--inbox {
  @media #{$media-md-up} {
    table-layout: fixed;
  }
  thead {
    @media #{$media-sm-down} {
      display: initial;
    }
  }
  thead th:not(:first-child) {
    @media #{$media-sm-down} {
      display: none;
    }
  }
  tbody tr {
    @media #{$media-sm-down} {
      position: relative;
      display: grid;
      grid-template-columns: 30px 1fr;
    }
    &:hover,
    &:focus {
      @media #{$media-md-up} {
        cursor: pointer;
        background-color: $table-active-row-color;
      }
    }
  }
  tr,
  .table__row {
    @media #{$media-md-up} {
      display: flex;
    }
  }
  th,
  td,
  .table__header,
  .table__cell {
    @media #{$media-md-up} {
      display: block;
      flex-shrink: 0;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      &:first-child {
        padding-left: 10px;
      }
    }
    &:nth-child(2) {
      @media #{$media-md-up} {
        width: 135px;
      }
    }
    &:nth-child(3) {
      @media #{$media-md-up} {
        flex-grow: 1;
        flex-shrink: 1;
      }
    }
    &:last-child {
      @media #{$media-md-up} {
        width: 350px;
      }
    }
  }
  th:first-child,
  td:first-child {
    @media #{$media-sm-down} {
      padding-top: 5px;
    }
    @media #{$media-md-up} {
      width: 145px;
    }
  }
  td:first-child {
    @media #{$media-sm-down} {
      grid-row-start: 1;
      grid-row-end: 4;
      //align-self: center;
    }
  }
  tfoot {
    tr:first-child {
      @media #{$media-md-up} {
        display: none;
      }
    }
    tr:last-child {
      td {
        width: 100%;
      }
    }
  }
  .icon--attachment {
    @media #{$media-md-up} {
      margin-right: 40px;
    }
  }
  .data-table-options {
    td {
      width: 100%;
    }
  }
}

.table--alerts {
  thead {
    @media #{$media-sm-down} {
      display: initial;
    }
  }
  tbody {
    tr {
      th {
        @media #{$media-md-up} {
          display: initial;
        }

        + td {
          @media #{$media-sm-down} {
            text-align: initial;
          }
        }
      }
    }
  }
  tr {
    @media #{$media-sm-down} {
      display: grid;
      grid-template-columns: 50% 50%;
    }
    &:first-child {
      vertical-align: top;

      td,
      th {
        padding-top: 0;
      }
    }
  }

  thead td:first-child,
  tfoot td:first-child {
    @media #{$media-sm-down} {
      display: none;
    }
  }
  thead td:nth-child(2),
  tfoot td:nth-child(2),
  thead td:nth-child(3),
  tfoot td:nth-child(3) {
    @media #{$media-md-up} {
      width:150px;
    }
  }
  thead tr:first-child {
    @media #{$media-sm-down} {
      display: none;
    }
  }
  tbody th {
    @media #{$media-sm-down} {
      padding-bottom: 10px;
      grid-column-start: 1;
      grid-column-end: 3;
    }
  }
  tfoot tr {
    @media #{$media-sm-down} {
      padding-top: 20px;
    }
  }
}

.table__details-link {
  display: block;
  //margin-top: 10px;
  @media #{$media-md-up} {
    display: none;
  }
}

.data-table-options {
  height: 47px;
  width: 100%;
  @media #{$media-md-up} {
    height: 56px;
  }
  &__option {
    display: none;
  }
  &--visible {
    .data-table-options__option {
      display: block;
    }
  }
}