.modal {
  display: none;
  position: absolute;
  z-index: 300;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  @media #{$media-md-up} {
    background-color: rgba(255,255,255,0.8);
  }
  &__content {
    position: relative;
    width: 100%;
    height: 100vh;
    margin: 0;
    padding: 20px;
    background-color: $modal-color;
    overflow-y: scroll; /* has to be scroll, not auto */
    -webkit-overflow-scrolling: touch;
    @media #{$media-md-up} {
      position: absolute;
      float: left;
      left: 50%;
      top: 50%;
      display: block;
      max-width: 430px;
      height: auto;
      padding: 30px;
      transform: translate(-50%, -50%);
      overflow: auto;
      box-shadow: 0 10px 40px 0 rgba(0, 0, 0, 0.1);
    }
  }

  .form {
    @media #{$media-sm-up} {
      max-width: 70%;
      margin-left: auto;
      margin-right: auto;
    }
    @media #{$media-md-up} {
      max-width: 100%;
    }
  }

  &__close-container {
    position: relative;
    display: block;
    width: 100%;
    height: 50px;
    margin-bottom: 30px;
  }

  &__close-button {
    position: absolute;
    top: 0;
    right: 0;
    padding: 0;
    border: none;
    cursor: pointer;
    background: transparent;
  }
  &--float {
    @media #{$media-md-up} {
      .modal__content {
        position: relative;
        float: none;
        left: auto;
        top: auto;
        transform: none;
        margin: 5% auto;
      }
    }
  }
  &--lg {
    .modal__content {
      @media #{$media-md-up} {
        max-width: 960px;
      }
    }
  }
}
.modal--open,
.modal--open .main-container {
  height: 100vh;
  overflow: hidden;
}