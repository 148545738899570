.jump-link {
  background-color: $jumplink-color;
  border: 1px solid $jumplink-border-color;
  &:focus,
  &:active {
    left: 5px;
    width: auto;
    height: auto;
    margin: 0;
    clip: auto;
  }
}