.uploader {
  margin-top: 20px;
  margin-bottom: 20px;
  padding-top: 50px;
  padding-bottom: 50px;
  padding-left: 30px;
  padding-right: 30px;
  text-align: center;
  background: $uploader-bg-color;
  border: 2px dashed $uploader-border-color;
}