/* Tooltip hidden by default */

/* position relative for containers */
.tooltip-simpletooltip_container {
  position: relative;
  display: inline-block;
  line-height: initial;
  font-size: 14px;
  font-family: $font-family-body;
  font-weight: 600;

  a {
    white-space: nowrap;
    &:focus,
    &:hover {
      text-decoration: underline;
    }
  }
}

.js-simple-tooltip {
  display: inline-block;
  cursor: pointer;
}

/* tooltip styles */
.tooltip-simpletooltip {
  position: absolute;
  z-index: 666;
  width: 386px;
  padding-top: 36px;
  padding-bottom: 20px;
  padding-left: 20px;
  padding-right: 20px;
  color: $tooltip-font-color;
  box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.2);
  background: $tooltip-color;
  text-align: left;

  @media #{$media-md-up} {
    width: 386px;
    padding-top: 20px;
    padding-right: 60px;
  }

  &--lg {
    @media #{$media-md-up} {
      width: 450px;
    }
  }

 &__arrow {
    speak: none;
    position: absolute;
    z-index: 666;
    width: 10px;
    height: 10px;
    pointer-events: none;
    bottom: 100%;
    margin: 0 auto;
    //left: 0;
    border: 10px solid transparent;
    border-bottom-color: $tooltip-color;
  }

  &[aria-hidden="true"] {
    display: none;
  }
  &[aria-hidden="false"] {
    display: block;
  }

  &:focus {
    outline: none;
  }

  &__content {
    font-size: 16px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.4210526315789473;
    letter-spacing: -0.3166666924953461px;

    @media #{$media-md-up} {
      font-size: 19px;
    }
  }

  &__image {
    max-width: 100%;
    &--border {
      border: 1px solid $tooltip-border-color;
    }
  }

  &__text {
    display: none;
    margin-left: 10px;
    @media #{$media-md-up} {
      display: inline;
    }
    &--no-hide {
      display: inline;
    }
  }
  &__close-button {
    appearance: none;
    position: absolute;
    right: 10px;
    top: 1px;
    width: 36px;
    height: 36px;
    padding: 0;
    font-size: 32px;
    font-weight: normal;
    color: $tooltip-close-color;
    text-align: center;
    border: none;
    background: $tooltip-color;
    cursor: pointer;

    &:focus {
      outline: 1px dotted #000;
    }
  }
}