.main-footer {
  background-color: $footer-color;
  &__content {
    padding-top: 30px;
    padding-bottom: 40px;
    @media #{$media-lg-up} {
      display: flex;
      padding-top: 26px;
      padding-bottom: 29px;
      justify-content: space-between;
      align-items: center;
    }
  }
  &__logo {
    display: block;
    height: 70px;
    img {
      height: 100%;
      width: auto;
    }
  }
  &__nav {
    padding-top: 35px;
    @media #{$media-lg-up} {
      padding-top: 0;
    }
  }
  &__list {
    margin: 0;
    padding: 0;
    list-style: none;
  }
  &__item {
    margin-top: 0;
    margin-bottom: 0;
    @media #{$media-lg-up} {
      margin-left: 24px;
      text-align: center;
    }
  }
  &__list {
    columns: 2;
    @media #{$media-sm-up} {
      columns: 3;
    }
    @media #{$media-md-up} {
      columns: 5;
    }
    @media #{$media-lg-up} {
      display: flex;
      columns: normal;
      align-items: center;
    }
  }
  &__link {
    display: inline-block;
    padding-bottom: 13px;
    font-size: 14px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: 27px;
    letter-spacing: normal;
    text-decoration: none;
    color: $footer-link-color;
    &:hover {
      text-decoration: underline;
    }
    @media #{$media-md-up} {
      padding-bottom: 0;
    }
  }
  &__copyright {
    background-color: $footer-color-copyright;
    p {
      margin: 0;
      padding-top: 18px;
      padding-bottom: 20px;
      font-size: 12px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: -0.1866666823625565px;
      color: $footer-font-color-copyright;
      @media #{$media-lg-up} {
        padding-top: 10px;
        padding-bottom: 15px;
      }
    }
    a {
      color: inherit;
      text-decoration: underline;
    }
  }
}