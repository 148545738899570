.box {
  display: flex;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 20px;
  padding-right: 20px;
  flex-direction: column;
  flex-grow: 1;
  justify-content: space-between;
  color: $box-font-color;
  text-decoration: none;
  background-color: $box-color;
  border-top: 6px solid $box-border-color;
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.08);
  transition: all 250ms cubic-bezier(.02, .01, .47, 1);

  &:hover,
  &:focus {
    text-decoration: none;
    @media #{$media-md-up} {
      box-shadow: 0 40px 40px rgba(0,0,0,.16);
      transform: translate(0,-20px);
    }
  }
  &--info {
    display: block;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 20px;
    padding-right: 20px;
    box-shadow: none;
    transition: none;
    background-color: $box-color-info;
    border-color: $box-border-color-info;
    @media #{$media-md-up} {
      height: auto;
      padding-top: 30px;
      padding-bottom: 30px;
      padding-left: 30px;
      padding-right: 30px;
    }
    &:hover {
      box-shadow: none;
      transform: none;
    }
    &--clean {
      background-color: $box-color-clean;
      border-left: 1px solid $box-border-color-alt;
      border-right: 1px solid $box-border-color-alt;
      border-bottom: 1px solid $box-border-color-alt;
    }
  }
  &--error {
    display: block;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 20px;
    padding-right: 20px;
    margin-bottom: 50px;
    box-shadow: none;
    transition: none;
    background-color: $box-color-error;
    border-color: $box-border-color-error;
    @media #{$media-md-up} {
      height: auto;
      padding-top: 30px;
      padding-bottom: 30px;
      padding-left: 30px;
      padding-right: 30px;
      margin-bottom: 30px;
    }
    &:hover {
      box-shadow: none;
      transform: none;
    }
  }
  &--full {
    height: 100%;
  }
  &--plain {
    border-top: none;
  }
  &--cta {
    display: flex;
    align-items: center;
    justify-content: center;
    @media #{$media-md-up} {
      flex-direction: row;
    }
  }
  &--icon {
    position: relative;
    padding-left: 70px;
    .icon {
      position: absolute;
      top: 25px;
      left: 20px;
      height: 30px;
      width: 30px;
      @media #{$media-md-up} {
        height: 48px;
        width: 48px;
      }

    }
    @media #{$media-md-up} {
      padding-left: 108px;
      .icon {
        top: 30px;
        left: 30px;
      }
    }
  }
  &--overview {
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 15px;
    padding-right: 15px;
    text-align: center;
    @media #{$media-md-up} {
      min-height: 365px;
    }
    &--contact {
      display: flex;
      justify-content: center;
    }
  }
  &__heading {
    margin: 0;
    padding-top: 0;
    padding-bottom: 10px;
    font-family: $font-family-heading;
    font-weight: 900;
    font-size: 19px;
    line-height: 1.4285714285714286;
    letter-spacing: -0.4375px;
    text-align: left;
    color: $box-font-color-heading;
    @media #{$media-md-up} {
      font-size: 21px;
    }
  }
  &__copy {
    margin: 0;
    flex-grow: 1;
  }
  &__download {
    position: relative;
    display: flex;
    height: 45px;
    margin-top: 24px;
    padding-top: 15px;
    align-items: center;
    border-top: 1px solid $box-border-color-alt;
    @media #{$media-md-up} {
      height: 66px;
      margin-top: 30px;
      padding-top: 20px;
      align-items: flex-start;
    }
    &:after {
      content: " ";
      position: absolute;
      top: 15px;
      right: 0;
      height: 30px;
      width: 30px;
      background: transparent url(/images/global/icons/download.svg) no-repeat center center;
      background-size: contain;
      @media #{$media-md-up} {
        width: 48px;
        height: 48px;
      }
    }
  }
  &__download-info {
    display: flex;
    height: 100%;
    padding-left: 5px;
    padding-right: 15px;
    padding-top: 3px;
    padding-bottom: 3px;
    flex-direction: column;
    justify-content: space-between;
  }
  &__download-name {
    font-size: 14px;
    line-height: 1;
    @media #{$media-md-up} {
      font-size: 16px;
    }
  }
  &__download-size {
    font-size: 11px;
    line-height: 1;
  }
}