// http://hugeinc.github.io/flexboxgrid-sass/
//
// -- Start editing -- //
//

// Set the number of columns you want to use on your layout.
$grid-columns: 12 !default;
// Set the gutter between columns.
$grid-gutter-width: 20px !default;
$grid-gutter-width-alt: 30px !default;
// Set a margin for the container sides.
$grid-outer-margin: 20px !default;
$grid-outer-margin-alt: 15px !default;

// Create or remove breakpoints for your project
// Syntax: name size,
$grid-breakpoints:
  sm 480,
  md 860,
  lg 1024 !default;
$grid-max-width: 11200 !default;

// Set the point where the gutters/margins switch
$grid-breakpoint-alt: 480 !default;

//
// -- Stop editing -- //
//

$gutter-compensation: $grid-gutter-width * .5 * -1;
$gutter-compensation-alt: $grid-gutter-width-alt * .5 * -1;
$half-gutter-width: $grid-gutter-width * .5;
$half-gutter-width-alt: $grid-gutter-width-alt * .5;

.wrapper {
  box-sizing: border-box;
  max-width: $grid-max-width;
  margin: 0 auto;
}

.container {
  max-width: $grid-max-width;
  margin-right: auto;
  margin-left: auto;
  padding-right: $grid-outer-margin;
  padding-left: $grid-outer-margin;
  @media only screen and (min-width: $grid-breakpoint-alt) {
    padding-right: $grid-outer-margin-alt;
    padding-left: $grid-outer-margin-alt;
  }
}

.row {
  box-sizing: border-box;
  display: flex;
  flex: 0, 1, auto;
  flex-direction: row;
  flex-wrap: wrap;
  margin-right: $gutter-compensation;
  margin-left: $gutter-compensation;
  @media only screen and (min-width: $grid-breakpoint-alt) {
    margin-right: $gutter-compensation-alt;
    margin-left: $gutter-compensation-alt;
  }
  &--seperators {
    [class*="col-"] + [class*="col-"] {
      position: relative;
      &:before {
        content: " ";
        position: relative;
        top: 0;
        left: 0;
        display: block;
        width: 100%;
        height: 2px;
        margin-top: 30px;
        margin-bottom: 30px;
        background-color: $color-gandalf;
      }
    }
    &--thin {
      [class*="col-"] + [class*="col-"] {
        &:before {
          display: none;
        }
      }
    }
  }
}

.row.reverse {
  flex-direction: row-reverse;
}

.col.reverse {
  flex-direction: column-reverse;
}

@mixin grid-sass-col-common {
  box-sizing: border-box;

  // split @include flex(0, 0, auto) into individual props
  flex-grow: 0;
  flex-shrink: 0;

  // we leave @include flex-basis(auto) out of common because
  // in some spots we need it and some we dont
  // more why here: https://github.com/kristoferjoseph/flexboxgrid/issues/126

  padding-right: $half-gutter-width;
  padding-left: $half-gutter-width;
  @media only screen and (min-width: $grid-breakpoint-alt) {
    padding-right: $half-gutter-width-alt;
    padding-left: $half-gutter-width-alt;
  }
}

$name: xs;
.col-#{$name} {
  @include grid-sass-col-common;
  flex-basis: auto;
}
@for $i from 1 through $grid-columns {
  .col-#{$name}-#{$i} {
    @include grid-sass-col-common;
    flex-basis: 100% / $grid-columns * $i;
    max-width: 100% / $grid-columns * $i;
  }
}
@for $i from 0 through $grid-columns {
  .col-#{$name}-offset-#{$i} {
    @include grid-sass-col-common;
    @if $i == 0 {
      margin-left: 0;
    } @else {
      margin-left: 100% / $grid-columns * $i;
    }
  }
}
@for $i from -3 through 10 {
  .#{$name}-mu-#{($i*10)} {
    margin-top: #{($i*10)}px;
  }
  .#{$name}-md-#{($i*10)} {
    margin-bottom: #{($i*10)}px;
  }
  .#{$name}-ml-#{($i*10)} {
    margin-left: #{($i*10)}px;
  }
  .#{$name}-mr-#{($i*10)} {
    margin-right: #{($i*10)}px;
  }
  .#{$name}-pu-#{($i*10)} {
    padding-top: #{($i*10)}px;
  }
  .#{$name}-pd-#{($i*10)} {
    padding-bottom: #{($i*10)}px;
  }
  .#{$name}-pl-#{($i*10)} {
    padding-left: #{($i*10)}px;
  }
  .#{$name}-pr-#{($i*10)} {
    padding-right: #{($i*10)}px;
  }
}
.col-#{$name} {
  flex-grow: 1;
  flex-basis: 0;
  max-width: 100%;
}
.start-#{$name} {
  justify-content: flex-start;
  text-align: left;
}

.center-#{$name} {
  justify-content: center;
  text-align: center;
}

.end-#{$name} {
  justify-content: flex-end;
  text-align: right;
}

.top-#{$name} {
  align-items: flex-start;
}

.middle-#{$name} {
  align-items: center;
}

.bottom-#{$name} {
  align-items: flex-end;
}

.around-#{$name} {
  justify-content: space-around;
}

.between-#{$name} {
  justify-content: space-between;
}

.first-#{$name} {
  order: -1;
}

.last-#{$name} {
  order: 1;
}

.#{$name}-hidden {
  display: none;
}

.#{$name}-block {
  display: block;
}

.#{$name}-inline {
  display: inline;
}

.#{$name}-flex {
  display: flex;
  flex-direction: column;
}
.#{$name}-table-cell {
  display: table-cell;
}
.#{$name}-table-row {
  display: table-row;
}
.#{$name}-table-head {
  display: table-header-group;
}
.#{$name}-table-foot {
  display: table-footer-group;
}
.#{$name}-flex-row {
  display: flex;
}
.#{$name}-initial {
  display: initial;
}


@each $breakpoint in $grid-breakpoints {
  $name: nth($breakpoint, 1);
  $size: nth($breakpoint, 2);
  @media only screen and (min-width: $size) {
    .col-#{$name} {
      @include grid-sass-col-common;
      flex-basis: auto;
    }
    .row--seperators {
      [class*="col-#{$name}-"] + [class*="col-#{$name}-"] {
        &:before {
          position: absolute;
          width: 2px;
          height: 100%;
          margin-top: 0;
          margin-bottom: 0;
        }
      }
      &--thin {
        [class*="col-#{$name}-"] + [class*="col-#{$name}-"] {
          &:before {
            display: block;
            width: 1px;
          }
        }
      }
    }
    @for $i from 1 through $grid-columns {
      .col-#{$name}-#{$i} {
        @include grid-sass-col-common;
        flex-basis: 100% / $grid-columns * $i;
        max-width: 100% / $grid-columns * $i;
      }
    }
    @for $i from 0 through $grid-columns {
      .col-#{$name}-offset-#{$i} {
        @include grid-sass-col-common;
        @if $i == 0 {
          margin-left: 0;
        } @else {
          margin-left: 100% / $grid-columns * $i;
        }
      }
    }

    @for $i from -3 through 10 {
      .#{$name}-mu-#{($i*10)} {
        margin-top: #{($i*10)}px;
      }
      .#{$name}-md-#{($i*10)} {
        margin-bottom: #{($i*10)}px;
      }
      .#{$name}-ml-#{($i*10)} {
        margin-left: #{($i*10)}px;
      }
      .#{$name}-mr-#{($i*10)} {
        margin-right: #{($i*10)}px;
      }
      .#{$name}-pu-#{($i*10)} {
        padding-top: #{($i*10)}px;
      }
      .#{$name}-pd-#{($i*10)} {
        padding-bottom: #{($i*10)}px;
      }
      .#{$name}-pl-#{($i*10)} {
        padding-left: #{($i*10)}px;
      }
      .#{$name}-pr-#{($i*10)} {
        padding-right: #{($i*10)}px;
      }
    }
    .col-#{$name} {
      flex-grow: 1;
      flex-basis: 0;
      max-width: 100%;
    }
    .start-#{$name} {
      justify-content: flex-start;
      text-align: left;
    }

    .center-#{$name} {
      justify-content: center;
      text-align: center;
    }

    .end-#{$name} {
      justify-content: flex-end;
      text-align: right;
    }

    .top-#{$name} {
      align-items: flex-start;
    }

    .middle-#{$name} {
      align-items: center;
    }

    .bottom-#{$name} {
      align-items: flex-end;
    }

    .around-#{$name} {
      justify-content: space-around;
    }

    .between-#{$name} {
      justify-content: space-between;
    }

    .first-#{$name} {
      order: -1;
    }

    .last-#{$name} {
      order: 1;
    }

    .#{$name}-hidden {
      display: none;
    }

    .#{$name}-block {
      display: block;
    }

    .#{$name}-inline {
      display: inline;
    }
    .#{$name}-flex {
      display: flex;
      flex-direction: column;
    }
    .#{$name}-flex-row {
      display: flex;
    }
    .#{$name}-table-cell {
      display: table-cell;
    }
    .#{$name}-table-row {
      display: table-row;
    }
    .#{$name}-table-head {
      display: table-header-group;
    }
    .#{$name}-table-foot {
      display: table-footer-group;
    }
    .#{$name}-initial {
      display: initial;
    }
  }
}