html {
  display: flex;
  box-sizing: border-box;
  min-width: 100%;
  height: 100vh;
}
*, *:before, *:after {
  box-sizing: border-box;
}

body {
  display: flex;
  min-width: 100%;
  min-height: 100vh;
  margin: 0;
  flex-direction: column;
  font-family: $font-family-body;
  font-size: 16px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 24px;
  letter-spacing: -0.27px;
  text-align: left;
  color: $font-color-body;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  @media #{$media-md-up} {
    font-size: 19px;
    line-height: 27px;
    letter-spacing: -0.32px;
  }
}
ul,
ol,
.list {
  margin-top: 0;
  margin-bottom: 30px;
  padding-left: 30px;
  ul {
    margin-top: 20px;
    margin-bottom: 0;
    list-style-type: disc;
  }
  a {
    text-decoration: underline;
  }
  &--links {
    list-style-type: none;
    padding-left: 0;
    a {
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
      word-wrap: break-word;
    }
  }
  &--plain {
    list-style-type: none;
    padding-left: 0;
  }
  &--checklist {
    position: relative;
    list-style-type: none;
    li {
      &:before {
        content: ' ';
        position: absolute;
        left: 6px;
        width: 8px;
        height: 20px;
        border-right: 2px solid $checklist-color;
        border-bottom: 2px solid $checklist-color;
        background: transparent;
        transform: rotate(35deg);
      }
    }
  }
}
li {
  margin-top: 0;
  & + li {
    margin-top: 20px;
  }
}

a {
  color: $color-link;
  text-decoration: none;
  word-break: break-word;
  &:hover,
  &:focus {
    text-decoration: underline;
  }
  &[href^="tel:"] {
    color: $color-link;
    text-decoration: inherit;
    white-space: nowrap;
    @media #{$media-md-up} {
      color: inherit;
      text-decoration: none;
    }
  }
  &[rel="external"] {
    display: inline-block;
    min-height: 28px;
    padding-left: 38px;
    background: transparent url(/images/global/icons/external-link.svg) no-repeat top left;
    background-size: 28px;
  }
}

p {
  margin-top: 0;
  margin-bottom: 0;
  word-break: break-word;
  & + p {
    margin-top: 20px;
  }
  a,
  a[href^="tel:"] {
    color: $color-link;
    text-decoration: underline;
    @media #{$media-md-up} {
      color: $color-link;
    }
  }
}

label {
  display: block;
}

sup {
  top: -0.4em;
  font-size: 55%;
}

hr {
  border: 1px solid $color-gandalf;
  border-top: none;
}

.main-container {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}