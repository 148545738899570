.button {
  display: inline-block;
  min-width: 170px;
  padding-top: 9px;
  padding-bottom: 9px;
  padding-left: 23px;
  padding-right: 23px;
  font-size: 19px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 0.7368421052631579;
  line-height: 1.2;
  letter-spacing: -0.2955555617809296px;
  color: $btn-font-color;
  text-align: center;
  text-decoration: none;
  border: none;
  box-shadow: none;
  background: $btn-color;
  background: linear-gradient(to left, $btn-color-alt 50%, $btn-color 50%);
  background-size: 200% 100%;
  background-position: right bottom;
  cursor: pointer;
  transition: background 400ms ease;
  vertical-align: middle;
  appearance: none;
  &:hover,
  &:focus {
    background-position: left bottom;
    text-decoration: none;
  }
  &__icon {
    top: -1px;
    margin-right: 8px;
  }
  &--hollow {
    padding-top: 11px;
    padding-bottom: 11px;
    padding-left: 21px;
    padding-right: 21px;
    color: $btn-font-color-hollow;
    border: 2px solid $btn-color-alt;
    background: $btn-color-hollow;
    background: linear-gradient(to left, $btn-color-hollow 50%, $btn-color 50%);
    background-size: 200% 100%;
    background-position: right bottom;
    &:hover,
    &:focus {
      color: $btn-font-color-hollow-hover;
    }
  }
  &--auto {
    min-width: 0;
  }
}