.marquee {
  display: flex;
  min-height: 56px;
  padding-top: 10px;
  padding-bottom: 5px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-color: $marquee-color;
  color: $marquee-font-color;
  text-align: center;
  @media #{$media-md-up} {
    min-height: 140px;
    padding-top: 13px;
    padding-bottom: 50px;
  }
  &__copy {
    margin: 0;
    padding: 0;
    @media #{$media-md-up} {
      font-size: 28px;
      line-height: 41px;
    }
  }
  &--md {
    min-height: 95px;
    padding-bottom: 38px;
    background-image: url(/images/global/interior-waves.svg);
    background-position: center bottom;
    background-repeat: repeat-x;
    background-size: 100% 47px;
    @media #{$media-md-up} {
      min-height: 250px;
      padding-top: 43px;
      padding-bottom: 115px;
      background-size: 100% 100px;
    }
  }
  &--lg {
    min-height: 180px;
    padding-top: 22px;
    padding-bottom: 60px;
    background-image: url(/images/global/interior-waves.svg);
    background-position: center bottom;
    background-repeat: repeat-x;
    background-size: 100% 47px;
    @media #{$media-md-up} {
      min-height: 429px;
      padding-top: 93px;
      padding-bottom: 180px;
      background-size: 100% 125px;
    }
  }
  &--lg-compressed {
    @media #{$media-md-up} {
      min-height: 345px;
      padding-top: 40px;
      padding-bottom: 145px;
    }
  }
  &--animate {
    position: relative;
    background-image: none;
    overflow: hidden;
    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      height: 47px;
      width: 5760px;
      background-image: url(/images/global/waves-full.svg);
      background-position: 0% 100%;
      background-repeat: repeat-x;
      animation: wave 20s cubic-bezier(.47,.23,.68,.84) infinite;
      @media #{$media-md-up}{
        height: 125px;
      }
    }
  }
}
@keyframes wave {
  from {
      background-position: 0% 100%;

  }
  to {
      background-position: 100% 100%;
  }
}