.radio {
  position: relative;
  padding-left: 50px;
  &__label {
    position: relative;
    display: inline-block;
    padding-left: 50px;
    padding-right: 60px;
    margin-left: -50px;
    line-height: 32px;
    cursor: pointer;
    color: $radio-font-color;

    &:last-of-type {
      padding-right: 0;
    }

    &:before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 32px;
      height: 32px;
      border: 2px solid $radio-border-color;
      border-radius: 100%;
      background: transparent;
    }

    &:after {
      content: '';
      width: 20px;
      height: 20px;
      background: $radio-checked-color;
      position: absolute;
      top: 6px;
      left: 6px;
      border-radius: 100%;
      transition: opacity 0.2s ease, transform 0.2s ease;
      opacity: 0;
      transform: scale(0);
    }
  }
  &__input {
    position: absolute;
    left: -9999px;
    &:checked  + .radio__label {
      &:before {
        border-color: $radio-checked-color;
      }
      &:after {
        opacity: 1;
        transform: scale(1);
      }
    }
    &:focus + .radio__label:before {
      outline: 1px dotted;
    }
  }
}