.tag {
  padding-left: 3px;
  padding-right: 3px;
  font-size: 13px;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.5px;
  color: $tag-font-color;
  &--green {
    background-color: $color-seafoam-green;
  }
}