.meter {
  height: 20px;
  line-height: 20px;
  font-size: 13px;
  vertical-align: 0;
  @media #{$media-xs-down} {
    span {
      display: block;
    }
  }
  &__bar {
    margin-left: 10px;
    margin-right: 10px;
    vertical-align: 0;
    width: 115px;
    height: 9px;
    border: 1px solid $meter-color;
    background: $meter-bg-color;
    @media screen and (min-width:0\0) {
      display: none !important;
    }
    @media #{$media-md-up} {
        width: 230px;
      }
    @media #{$media-xs-down} {
        display: inline-block;
        margin-left: -5px;
        width: 70%;
        border: none;
    }
    &::-webkit-meter-bar {
      width: 115px;
      height: 8px;
      //margin-top: 1px;
      border: 1px solid $meter-color;
      background: $meter-bg-color;
      @media #{$media-md-up} {
        width: 230px;
      }
      @media #{$media-xs-down} {
        display: inline-block;
        margin-left: 5px;
        margin-right: 5px;
        width: 100%;
      }
    }
    &::-moz-meter-bar {
      width: 115px;
      height: 6px;
      border: 1px solid $meter-color;
      background: $meter-bg-color;
      @media #{$media-md-up} {
        width: 230px;
      }
      @media #{$media-xs-down} {
        display: inline-block;
        margin-left: 5px;
        margin-right: 5px;
        width: 100%;
      }
    }
    &::-webkit-meter-optimum-value,
     ::-webkit-meter-suboptimum-value,
     ::-webkit-meter-even-less-good-value {
      background: $meter-color;
      transition: width .5s;
    }
    &:-moz-meter-optimum::-moz-meter-bar,
     :-moz-meter-sub-optimum::-moz-meter-bar,
     :-moz-meter-sub-sub-optimum::-moz-meter-bar {
      background: $meter-color;
      transition: width .5s;
     }
  }
  &__strength {
    display: inline-block;
    width: 70px;
    font-weight: bold;
    color: $meter-color;

    &--strong {
      background-image: url('/images/global/icons/check.svg');
      background-repeat: no-repeat;
      background-position: right center;
      background-color: transparent;
      background-size: contain;
    }
  }
}