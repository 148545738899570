.prevnext {
  display: flex;
  justify-content: space-between;
  &--border {
    padding-bottom: 10px;
    border-bottom: 1px solid $prevnext-border-color;
  }
  &__link {
    position: relative;
    display: block;
    min-height: 30px;
    margin-left: auto;
    padding-right: 40px;
    text-align: right;
    text-decoration: none;
    line-height: 30px;
    &:after {
      content: " ";
      position: absolute;
      top: 50%;
      right: 0;
      width: 30px;
      height: 30px;
      background: transparent url(/images/global/icons/arrow-right.svg) no-repeat center right;
      background-size: contain;
      transform: translateY(-50%);
      @media #{$media-md-up} {
        width: 48px;
        height: 48px;
      }
    }
    @media #{$media-md-up} {
      min-height: 48px;
      padding-right: 68px;
      line-height: 1.4210526315789473;
      transition: padding 300ms ease;
      &:hover {
        padding-right: 88px;
      }
    }
    &--prev {
      margin-left: 0;
      padding-left: 40px;
      padding-right: 0;
      text-align: left;
      @media #{$media-md-up} {
        padding-left: 68px;
        &:hover {
          padding-left: 88px;
        }
      }
      &:after {
        right: auto;
        left: 0;
        transform: translateY(-50%) rotate(180deg);
      }
    }
    &--sm {
      line-height: 1.5;
      @media #{$media-md-up} {
        min-height: 30px;
        padding-left: 50px;
        &:hover {
          padding-left: 60px;
        }
        &:after {
          left: 10px;
          width: 30px;
          height: 30px;
        }
      }

      .prevnext__content {
        display: block;
      }
    }
  }
  &__content {
    display: none;
    @media #{$media-md-up} {
      display: block;
    }
  }
}