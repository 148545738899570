.text-area {
  width: 100%;
  min-height: 230px;
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 20px;
  padding-right: 20px;
  font-size: 19px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.4210526315789473;
  letter-spacing: -0.3166666924953461px;
  text-align: left;
  color: $textarea-font-color;
  border: 2px solid $textarea-border-color;
  resize: none;
  appearance: none;
  &:focus { outline: 1px dotted $textarea-focus-color; }
}