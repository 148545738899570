.checkbox {
  display: inline-block;
  &__label {
    position: relative;
    display: inline-block;
    min-width: 18px;
    min-height: 18px;
    padding-left: 30px;
    font-size: 14px;
    line-height: normal;
    cursor: pointer;
    background: transparent;
    &:before {
      content: '';
      position: absolute;
      left: 0;
      top: 1px;
      width: 18px;
      height: 18px;
      border: 2px solid $checkbox-border-color;
      background: transparent;
    }
    &:after {
      content: '';
      position: absolute;
      top: 2px;
      left: 5px;
      width: 7px;
      height: 15px;
      border-right: 2px solid $checkbox-checked-color;
      border-bottom: 2px solid $checkbox-checked-color;
      background: transparent;
      transform: rotate(35deg);
      opacity: 0;
    }
  }
  &__input {
    position: absolute;
    left: -9999px;
    &:checked  + .checkbox__label {
      &:before {
        border-color: $checkbox-checked-border-color;
        background: $checkbox-bg-color;
      }
      &:after {
        opacity: 1;
      }
    }
    &:focus + .checkbox__label {
      &:before {
        outline: 1px dotted;
      }
    }
    &[disabled] {
      &:checked  + .checkbox__label {
        &:before {
          background: transparent;
          border-color: $checkbox-border-color;
        }
        &:after {
          border-color: $checkbox-disabled-color;
        }
      }
    }
  }
  &--alone {
    display: inline-block;
    .checkbox__label {
      padding: 0;
    }
  }
  &--table {
    .checkbox__label {
      width: 100%;
      font-weight: 600;
      font-size: 16px;
      color: $color-text;
      @media #{$media-md-up} {
        font-size: 19px;
      }
      &:before {
        top: 50%;
        transform: translateY(-50%);
      }
      &:after {
        top: 45%;
        transform: translateY(-50%) rotate(35deg);
      }
    }
  }
  &--content-to-alone {
    .checkbox__label {
      @media #{$media-md-up} {
        padding: 0;
      }
    }
  }
}