.tracker-panel {
  display: flex;
  margin-top: 20px;
  flex-direction: column;
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.11);
  @media only screen and (min-width: 520px) {
    flex-direction: row;
  }
  &__tracker {
    display: flex;
    padding-top: 20px;
    padding-bottom: 20px;
    flex-direction: column;
    flex-grow: 1;
    justify-content: center;
  }
  &__icon,
  &__heading,
  &__copy {
    margin-left: auto;
    margin-right: auto;
    padding-left: 20px;
    padding-right: 20px;
    text-align: center;
  }
  &__info {
    @media #{$media-md-up} {
      width: 270px;
      flex-grow: 0;
    }
  }
}