.cta {
  display: flex;
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 20px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  .button {
    &:hover,
    &:focus {
      text-decoration: none;
    }
  }
  @media #{$media-md-up} {
    padding-top: 30px;
    flex-wrap: wrap;
    flex-direction: row;
  }
  a,
  .button {
    & + a,
    & + .button {
      margin-top: 15px;
    }
    @media #{$media-md-up} {
      margin-left: 15px;
      margin-right: 15px;
      & + a,
      & + .button {
        margin-top: 0;
      }
    }
  }

  &--center-to-left {
    @media #{$media-md-up} {
      justify-content: flex-start;
      text-align: left;
      a,
      .button {
        margin-left: 0;
        margin-right: 0;
        & + a,
        & + .button {
          margin-right: 15px;
        }
        & + a {
          margin-left: 15px;
        }
      }
    }
  }
  &--left {
    justify-content: flex-start;
    align-items: flex-start;
    text-align: left;
    a,
    .button {
      margin-left: 0;
      margin-right: 0;
      & + a,
      & + .button {
        margin-right: 15px;
      }
      & + a {
        margin-left: 15px;
      }
    }
  }
  &--stacked {
    @media #{$media-md-up} {
      flex-direction: column;
    }
    a,
    .button {
      margin-left: 0;
      margin-right: 0;
      & + a,
      & + .button {
        margin-left: 0;
        @media #{$media-md-up} {
          margin-top: 20px;
          margin-left: 0;
          margin-right: 0;
        }
      }
    }
  }
  &--form {
    padding-top: 30px;
    @media #{$media-md-up} {
      padding-top: 50px;
    }
  }

}