.text-center {
  text-align: center;
}

.text-sm {
  font-size: 13px;
  //line-height: 20px;
  line-height: inherit;
}

.legalese {
  font-size: 13px;
  line-height: 1.54;
}

.text-lg {
  font-size: 24px;
}

.text-xl {
  font-size: 32px;
}

.text-darker {
  color: $color-midnight;
}

.text-warning {
  color: $color-ripe-tomato;
}

.text-bold {
  color: $color-midnight;
  font-weight: 900;
  font-family: $font-family-heading;
}

.text-underline {
  text-decoration: underline;
}

.highlight-bg {
  background-color: $color-chalk;
  &--mobile-only {
    @media #{$media-md-up} {
      background-color: transparent;
    }
  }
}

.trademark {
  vertical-align: super;
  font-size: 10px;
}

.nowrap {
  white-space: nowrap;
  div {
    white-space: initial;
  }
}

_:-ms-fullscreen,
:root .main-container {
  display: block;
}

.no-scroll {
  overflow: hidden;
}

.hidden {
  display: none !important;
}

