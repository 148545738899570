//------------------------------------------------------------
// Colors
//------------------------------------------------------------
$color-midnight     : #002d5c;
$color-dusk         : #004488;
$color-bayview-night: #005288;
$color-velvet       : #004f9f;
$color-aquarium     : #005cb9;
$color-greek-ocean  : #006cd9;
$color-bayview-blue : #0d96d4;
$color-ninja-turtle : #1e8935;
$color-slime-green  : #35a94e;
$color-seafoam-green: #d3f7e5;
$color-ripe-tomato  : #e30000;
$color-valentine    : #ffe5e5;
$color-sunshine     : #ebcf00;
$color-granite      : #4a5773;
$color-rochester-sky: #9b9b9b;
$color-gandalf      : #dedede;
$color-gandalf-gray : #cccccc;
$color-chalk        : #f5f5f5;
$color-white        : #ffffff;

// TODO Use functional color groups
$color-border     : $color-gandalf;
$color-text       : $color-granite;
$color-text-alt   : $color-midnight;
$color-link       : $color-greek-ocean;
$color-bg         : $color-chalk;
$color-bg-dark    : $color-dusk;
$color-bg-dark-alt: $color-midnight;
$color-accent     : $color-ninja-turtle;
$color-accent-alt : $color-slime-green;
$color-error      : $color-ripe-tomato;
$color-error-alt  : $color-valentine;
$color-disabled   : $color-chalk;


//------------------------------------------------------------
// Typography
//------------------------------------------------------------
$font-family-heading  : 'Merriweather', Georgia, serif;
$font-family-body     : 'Open Sans', Arial, sans-serif;
$font-color-body      : $color-text;
$font-color-heading   : $color-text-alt;
$font-color-heading-ko: $color-white;
$font-size            : 16px;
$font-size-alt        : 19px;
$font-size-sm         : 13px;
$font-weight-body     : 600;
$font-weight-bold     : 900;

//------------------------------------------------------------
// Breakpoints
//------------------------------------------------------------
$media-xs-max: 479px;
$media-sm-min: 480px;
$media-sm-max: 859px;
$media-md-min: 860px;
$media-md-max: 1023px;
$media-lg-min: 1024px;
$media-lg-max: 1199px;
$media-xl-min: 1200px;

$break-nav-up  : 990px;
$break-nav-down: 989px;
$break-nav-alt-up: 1061px;
$break-nav-alt-down: 1060px;

//------------------------------------------------------------
// Media Query Shortcuts
// @media #{$media-sm-viewport} {
//
// }
//
// TODO: Media query include - @include media-breakpoint-up(sm)
//------------------------------------------------------------
$media-sm-up: "only screen and (min-width: #{$media-sm-min})";
$media-md-up: "only screen and (min-width: #{$media-md-min})";
$media-lg-up: "only screen and (min-width: #{$media-lg-min})";
$media-xl-up: "only screen and (min-width: #{$media-xl-min})";

$media-xs-down: "only screen and (min-width:1px) and (max-width: #{$media-xs-max})";
$media-sm-down: "only screen and (min-width:1px) and (max-width: #{$media-sm-max})";
$media-md-down: "only screen and (min-width:1px) and (max-width: #{$media-md-max})";
$media-lg-down: "only screen and (min-width:1px) and (max-width: #{$media-lg-max})";

$media-xs-only: "only screen and (max-width: #{$media-xs-max})";
$media-sm-only: "only screen and (min-width: #{$media-sm-min}) and (max-width: #{$media-sm-max})";
$media-md-only: "only screen and (min-width: #{$media-md-min}) and (max-width: #{$media-md-max})";
$media-lg-only: "only screen and (min-width: #{$media-lg-min}) and (max-width: #{$media-lg-max})";
$media-xl-only: "only screen and (min-width: #{$media-xl-min})";

$nav-breakpoint-up: "only screen and (min-width: #{$break-nav-up})";
$nav-breakpoint-down: "only screen and (min-width:1px) and (max-width: #{$break-nav-down})";
$nav-breakpoint-alt-up: "only screen and (min-width: #{$break-nav-alt-up})";
$nav-breakpoint-alt-down: "only screen and (min-width:1px) and (max-width: #{$break-nav-alt-down})";

//------------------------------------------------------------
// Grid
//------------------------------------------------------------
$grid-columns     : 12;
$grid-gutter-width: 20px;
$grid-outer-margin: 20px;
$grid-max-width   : $media-xl-min;
$grid-breakpoints :
  sm $media-sm-min,
  md $media-md-min,
  lg $media-lg-min;

// Apply slightly different grid gutters/margins at breakpoint
$grid-breakpoint-alt  : $media-sm-min;
$grid-outer-margin-alt: 15px;
$grid-gutter-width-alt: 30px;

//------------------------------------------------------------
// Alert
//------------------------------------------------------------
$alert-color                  : $color-seafoam-green;
$alert-color-announcement     : $color-white;
$alert-color-callout          : $color-midnight;
$alert-font-color             : $color-midnight;
$alert-font-color-announcement: $color-midnight;
$alert-font-color-dismiss     : $color-bayview-night;
$alert-font-color-callout     : $color-white;

//------------------------------------------------------------
// Box
//------------------------------------------------------------
$box-color             : $color-white;
$box-color-info        : $color-chalk;
$box-color-error       : $color-chalk;
$box-color-clean       : $color-white;
$box-font-color        : $font-color-body;
$box-font-color-heading: $font-color-heading;
$box-border-color      : $color-ninja-turtle;
$box-border-color-info : $color-greek-ocean;
$box-border-color-error: $color-error;
$box-border-color-alt  : $color-gandalf;

//------------------------------------------------------------
// Breadcrumbs
//------------------------------------------------------------
$breadcrumbs-color     : $color-chalk;
$breadcrumbs-font-color: $font-color-body;

//------------------------------------------------------------
// Buttons
//------------------------------------------------------------
$btn-color                  : $color-ninja-turtle;
$btn-color-alt              : $color-slime-green;
$btn-color-hollow           : transparent;
$btn-font-color             : $color-white;
$btn-font-color-hollow      : $color-midnight;
$btn-font-color-hollow-hover: $color-white;

//------------------------------------------------------------
// Checkbox
//------------------------------------------------------------
$checkbox-font-color          : $font-color-body;
$checkbox-border-color        : $color-gandalf-gray;
$checkbox-checked-color       : $color-white;
$checkbox-checked-border-color: $color-greek-ocean;
$checkbox-bg-color            : $color-greek-ocean;
$checkbox-disabled-color      : $color-rochester-sky;

//------------------------------------------------------------
// Checklist
//------------------------------------------------------------
$checklist-color     : $color-midnight;

//------------------------------------------------------------
// Footer
//------------------------------------------------------------
$footer-color               : $color-chalk;
$footer-color-copyright     : $color-midnight;
$footer-link-color          : $color-greek-ocean;
$footer-font-color-copyright: $color-white;

//------------------------------------------------------------
// Header
//------------------------------------------------------------
$header-color            : $color-dusk;
$header-color-search     : $color-greek-ocean;
$header-font-color       : $color-white;
$header-font-color-search: $color-white;
$header-menu-color       : $color-white;
$header-border-color     : $color-white;

//------------------------------------------------------------
// Icons
//------------------------------------------------------------
$icon-color-unread       : $color-sunshine;
$icon-color-unread-border: $color-dusk;

//------------------------------------------------------------
// Input
//------------------------------------------------------------
$input-color         : inherit;
$input-error         : $color-error;
$input-error-alt     : $color-error-alt;
$input-disabled-color: $color-disabled;
$input-font-color    : $font-color-body;
$input-border-color  : $color-gandalf;
$input-focus-color   : $color-greek-ocean;
$input-focus-border  : $color-midnight;
$input-password-color: $color-greek-ocean;

//------------------------------------------------------------
// Jump Links
//------------------------------------------------------------
$jumplink-color       : $color-chalk;
$jumplink-border-color: $color-gandalf;

//------------------------------------------------------------
// Language Bar
//------------------------------------------------------------
$lang-color     : $color-midnight;
$lang-font-color: $color-white;

//------------------------------------------------------------
// Marquee
//------------------------------------------------------------
$marquee-color     : $color-dusk;
$marquee-font-color: $color-white;

//------------------------------------------------------------
// Message
//------------------------------------------------------------
$message-border-color     : $color-gandalf;

//------------------------------------------------------------
// Meter
//------------------------------------------------------------
$meter-color   : $color-dusk;
$meter-bg-color: $color-white;

//------------------------------------------------------------
// Modal
//------------------------------------------------------------
$modal-color     : $color-white;

//------------------------------------------------------------
// PrevNext
//------------------------------------------------------------
$prevnext-border-color     : $color-gandalf;

//------------------------------------------------------------
// Radio
//------------------------------------------------------------
$radio-font-color   : $font-color-body;
$radio-border-color : $color-gandalf-gray;
$radio-checked-color: $color-greek-ocean;
$radio-lozenge-color: $color-seafoam-green;

//------------------------------------------------------------
// Rollups
//------------------------------------------------------------
$rollup-border-color      : $color-gandalf;
$rollup-cta-color         : $color-chalk;
$rollup-font-color-btn    : $font-color-heading;
$rollup-font-color-btn-alt: $font-color-body;
$rollup-link-color        : $color-link;

//------------------------------------------------------------
// Section
//------------------------------------------------------------
$section-color-dark     : $color-dusk;
$section-color-light    : $color-chalk;
$section-font-color-dark: $color-white;
$section-border-color   : $color-gandalf;

//------------------------------------------------------------
// Select
//------------------------------------------------------------
$select-arrow-color: $font-color-body;
$select-focus-color: $color-midnight;

//------------------------------------------------------------
// Tag
//------------------------------------------------------------
$tag-font-color: $color-midnight;

//------------------------------------------------------------
// Table
//------------------------------------------------------------
$table-border-color        : $color-gandalf;
$table-header-color        : $color-midnight;
$table-active-row-color    : $color-chalk;
$table-alt-row-color       : $color-chalk;
$table-empty-color         : transparent;
$table-empty-inverted-color: $color-chalk;
$table-gutter-offset       : $grid-outer-margin;
$table-gutter-offset-alt   : $grid-outer-margin-alt;
$table-grid-breakpoint     : $grid-breakpoint-alt;

//------------------------------------------------------------
// Table List
//------------------------------------------------------------
$table-list-border-color: $color-gandalf;

//------------------------------------------------------------
// TabNav
//------------------------------------------------------------
$tabnav-color-hover      : $color-chalk;
$tabnav-color-hover-alt  : $color-white;
$tabnav-active-color     : $color-slime-green;
$tabnav-select-arrow     : $color-slime-green;
$tabnav-font-color-select: $color-greek-ocean;

//------------------------------------------------------------
// Textarea
//------------------------------------------------------------
$textarea-font-color  : $font-color-body;
$textarea-border-color: $color-gandalf;
$textarea-focus-color : $color-midnight;

//------------------------------------------------------------
// Tooltip
//------------------------------------------------------------
$tooltip-font-color: $font-color-body;
$tooltip-color: $color-white;
$tooltip-border-color: $color-gandalf;
$tooltip-close-color: $color-bayview-night;

//------------------------------------------------------------
// Uploader
//------------------------------------------------------------
$uploader-bg-color    : $color-chalk;
$uploader-border-color: $color-rochester-sky;

//------------------------------------------------------------
// Video
//------------------------------------------------------------
$video-color: $color-rochester-sky;
$video-font-color: $color-white;