.rollup {
  border-bottom: 1px solid $rollup-border-color;
  &__trigger {
    margin: 0;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 0;
    padding-right: 0;
    font-weight: normal;
    @media #{$media-md-up} {
      padding-top: 20px;
      padding-bottom: 20px;
    }
  }
  &__cta {
    display: flex;
    padding-top: 32px;
    padding-bottom: 32px;
    padding-left: 30px;
    padding-right: 30px;
    flex-direction: column;
    background-color: $rollup-cta-color;
    @media #{$media-md-up} {
      flex-direction: row;
      justify-content: space-between;
    }
    a {
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
      & + a {
        margin-left: 50px;
      }
    }
  }
  &__sub-heading {
    display: none;
    margin-top: 10px;
    font-family: $font-family-body;
    font-size: 19px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.4210526315789473;
    letter-spacing: -0.3166667px;
    color: $rollup-font-color-btn-alt;
    @media #{$media-md-up} {
      display: block;
    }
  }
  &__button {
    position: relative;
    display: block;
    width: 100%;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 50px;
    padding-right: 0;
    font-family: $font-family-heading;
    font-weight: 900;
    font-size: 21px;
    line-height: 30px;
    letter-spacing: -0.44px;
    text-align: inherit;
    color: $rollup-font-color-btn;
    border: none;
    background: none;
    cursor: pointer;
    appearance: none;
    transition: padding 200ms ease-out;
    &:after {
      content: "";
      position: absolute;
      top: 9px;
      left: 0;
      right: auto;
      width: 30px;
      height: 30px;
      background: transparent url(/images/global/icons/expand.svg) no-repeat center center;
      background-size: contain;
      transition: transform 200ms ease-out;
      @media #{$media-md-up} {
        top: 10px;
      }
    }
    &:hover {
      @media #{$media-md-up} {
        padding-left: 70px;
      }
      @media (hover: none) {
        padding-left: 50px;
      }
    }
    &[aria-expanded="true"]:after {
      transform: rotate(135deg);
    }
  }
  &__icon {
    position: absolute;
    top: 10px;
    left: 0;
    width: 30px;
    height: 30px;
    @media #{$media-md-up} {
      width: 48px;
      height: 48px;
    }
  }
  &__target {
    display: none;
    padding-bottom: 30px;
    @media #{$media-md-up} {
      padding-bottom: 50px;
    }
    &--cta {
      padding-bottom: 0;
    }
  }

  &--preview {
    border-bottom: none;
    .rollup__target {
      position: relative;
      display: block;
      height: 152px;
      padding-bottom: 20px;
      overflow: hidden;
      &:after {
        content: '';
        position: absolute;
        z-index: 1;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 80px;
        pointer-events: none;
        background-image: linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(245,245,245,1) 84%);
      }
      &[data-rollup="false"] {
        &:after {
          display: none;
        }
      }
    }
    .rollup__trigger {
      padding-top: 0;
      padding-bottom: 0;
    }
    .rollup__button {
      font-size: 19px;
      font-weight: 700;
      color: $rollup-link-color;
      font-family: $font-family-body;
    }
  }

  &--lg {
    .rollup__trigger {
      @media #{$media-md-up} {
        padding-top: 29px;
        padding-bottom: 28px;
      }
    }
    .rollup__button {
      padding-left: 0;
      padding-right: 40px;
      @media #{$media-md-up} {
        padding-right: 70px;
        font-size: 24px;
        line-height: 33px;
        letter-spacing: -0.5px;
      }
      &:after {
        top: 10px;
        left: auto;
        right: 0;
        background: transparent url(/images/global/icons/chevron.svg) no-repeat center center;
        background-size: 100%;
        @media #{$media-md-up} {
          top: 2px;
          width: 48px;
          height: 48px;
        }
      }
      &:hover {
        @media #{$media-md-up} {
          padding-left: 20px;
        }
        @media (hover: none) {
          padding-left: 0;
        }
      }
      &[aria-expanded="true"]:after {
        transition-duration: 400ms;
        transform: rotate(180deg);
      }
    }
  }

  &--table {
    .rollup__target {
      padding-bottom: 0;
      tbody tr:last-child {
        border-bottom: none;
      }
    }
  }
}