.flatpickr-calendar {
  width: auto;
  max-width: 385px;
  padding-top: 50px;
  box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.1);
  &:before {
    display: none;
  }
  &:after {
    left: 50%;
    border-width: 14px;
    transform: translateX(-50%);
  }
  &.arrowBottom {
    &:after {
      top: 99%;
    }
  }
  &__close {
    position: absolute;
    top: 10px;
    right: 10px;
  }
  .flatpickr-months {
    align-items: center;
  }
  .flatpickr-month,
  .flatpickr-current-month {
    display: flex;
    height: 50px;
    padding: 0;
    align-items: center;
    justify-content: center;
  }
  .flatpickr-current-month {
    position: relative;
    left: auto;
    width: auto;
    .cur-month,
    .cur-year {
      color: $font-color-heading;
      font-size: 21px;
      font-family: $font-family-heading;
      font-weight: $font-weight-bold;
    }
    .cur-month {
      &:hover,
      &:focus {
        background: none;
      }
    }
  }
  .flatpickr-prev-month,
  .flatpickr-next-month {
    display: block;
    position: relative;
    height: 30px;
    width: 30px;
    padding: 0;
    margin-left: 10px;
    svg {
      display: none;
    }
    &:before {
      content: " ";
      position: absolute;
      top: 50%;
      right: 0;
      width: 30px;
      height: 30px;
      background: transparent url(/images/global/icons/arrow-right.svg) no-repeat center right;
      background-size: contain;
      transform: translateY(-50%) rotate(180deg);
    }
    &.disabled {
      display: block;
      cursor: inherit;
      &:before {
        display: none;
      }
    }
  }
  .flatpickr-next-month {
    margin-left: 0;
    margin-right: 10px;
    &:before {
      transform: translateY(-50%) rotate(0deg);
    }
  }
  .flatpickr-weekdays {
    height: 20px;
    border-bottom: 1px solid $color-gandalf;
  }
  .flatpickr-weekdaycontainer {
    height: 100%;
  }
  .flatpickr-weekday {
    display: flex;
    justify-content: center;
    align-items: center;
    color: $font-color-body;
    font-size: $font-size-sm;
    font-weight: $font-weight-body;
    font-family: $font-family-body;
    &:first-child,
    &:last-child {
      background-color: $color-chalk;
    }
  }
  .flatpickr-days {
    width: auto;
  }
  .dayContainer {
    width: auto;
    min-width: 0;
    max-width: 100%;
    @media #{$media-sm-up} {
      width: 385px;
    }
  }
  .flatpickr-day {
    position: relative;
    max-width: 100%;
    width: 55px;
    height: 55px;
    flex-basis: 14.285714%;
    line-height: 51px;
    color: $font-color-body;
    font-size: $font-size-sm;
    font-weight: $font-weight-body;
    border-radius: 0;
    border: none;
    border-bottom: 2px solid $color-gandalf;
    &.nextMonthDay,
    &.prevMonthDay {
      color: $color-gandalf-gray;
    }
    &.today,
    &.selected,
    &:hover,
    &:focus {
      border: none;
      border-bottom: 2px solid $color-gandalf;
      background: none;
      &:before {
        content: '';
        position: absolute;
        top: 49%;
        left: 50%;
        z-index: -1;
        width:32px;
        height: 32px;
        border-radius: 50%;
        border: 1px solid $color-gandalf-gray;
        transform: translate(-50%, -50%);
      }
    }
    &:hover,
    &:focus {
      color: $font-color-body;
      &:before {
        background-color: $color-gandalf-gray;
        // Hide the background for touch events so we don't get the
        // dreaded double click event
        // https://css-tricks.com/annoying-mobile-double-tap-link-issue/
        @media (pointer: coarse) { 
          display: none;
        }
      }
    }
    &:focus {
      outline: 1px dotted $color-midnight;
      outline-offset: -1px;
    }
    &.selected {
      color: $color-white;
      &:before {
        background-color: $color-greek-ocean;
        border-color: $color-greek-ocean;
        // Make sure touch devices DO show the selected before element
        @media (pointer: coarse) {
          display: block;
        }
      }
    }
    &.disabled,
    &.disabled:hover,
    &.disabled:focus {
      background-color: $color-chalk;
      color: $font-color-body;
      border-radius: 0;
      &:before {
        display: none;
      }
    }
  }
}