.section {
  padding-top: 30px;
  padding-bottom: 30px;
  @media #{$media-md-up} {
    padding-top: 50px;
    padding-bottom: 50px;
  }
  &--plain-list {
    .section__list {
      padding-left: 0;
      list-style-type: none;
    }
  }
  &--compressed {
    padding-top: 20px;
    padding-bottom: 20px;
    @media #{$media-md-up} {
      padding-top: 40px;
      padding-bottom: 40px;
    }
  }
  &--compressed-md {
    @media #{$media-md-up} {
      padding-top: 25px;
      padding-bottom: 25px;
    }
  }
  &--shadow {
    box-shadow: inset 0 60px 60px -60px rgba(0,0,0,0.1);
  }
  &--dark {
    background-color: $section-color-dark;
    color: $section-font-color-dark;
    a,
    a[href^="tel:"] {
      color: inherit;
    }
    a[href^="tel:"] {
      text-decoration: none;
    }
  }
  &--light {
    background-color: $section-color-light;
  }
  &--center {
    text-align: center;
  }
  &--pull-up {
    padding-top: 0;
    @media #{$media-md-up} {
      padding-top: 0;
    }
  }
  &--breath-bottom-lg {
    @media #{$media-md-up} {
      padding-bottom: 76px;
    }
  }
  &--breath-bottom-sm {
    padding-bottom: 10px;
  }
  &__copy {
    margin: 0;
  }
  &__seperator {
    margin-left: auto;
    margin-right: auto;
    margin-top: 30px;
    margin-bottom: 30px;
    height: 2px;
    width: 100%;
    background-color: $section-border-color;
    @media #{$media-md-up} {
      margin-top: 0;
      margin-bottom: 0;
      height: 100%;
      width: 2px;
      flex: 1;
    }
    &--delay-horizontal {
      @media #{$media-sm-up} {
        margin-top: 0;
        margin-bottom: 0;
        height: 100%;
        width: 2px;
        flex: 1;
      }
    }
  }
}