.input-group {
  position: relative;
  margin-bottom: 50px;
  & input {
    &[disabled="disabled"] {
      background-color: $input-disabled-color;
      border-color: $input-disabled-color;
    }
  }
  &__label {
    position: absolute;
    top: 9px;
    left: 0;
    line-height: 1;
    transition: 200ms ease-out;
    pointer-events: none;
  }
  &__icon {
    position: absolute;
    top: 0;
    width: 40px;
    height: 40px;
    padding: 0;
    background-color: transparent;
    border: none;
    appearance: none;
  }
  &__button {
    position: absolute;
    top: 0;
    width: 40px;
    height: 40px;
    padding: 0;
    background-color: transparent;
    border: none;
    appearance: none;
    border-radius: 0;
  }
  &__helper {
    display: block;
    font-size: 13px;
    line-height: 1.6;
  }
  &__prepend {
    padding-top: 6px;
    padding-bottom: 6px;
    border-bottom: 2px solid $input-border-color;
    display: none;
  }
  &__group {
    position: relative;
    display: flex;
  }
  &--in-focus {
    .input-group__label {
      left: 0;
      top: -14px;
      font-size: 11px;
      color: $input-focus-color;
    }
    .input {
      border-bottom-color: $input-focus-color;
    }
    .input-group__prepend {
      display: block;
      border-bottom-color: $input-focus-color;
      opacity: 1;
    }
    .text-area {
      border-color: $input-focus-color;
    }
  }
  &--prepend {

    .input-group__icon {
      left: 0;
    }
  }
  &--append {
    .input-group__input {
      padding-right: 40px;
    }
    .input-group__button {
      right: 0;
    }
    .input-group__icon {
      right: 0;
    }
  }
  &--tooltip {
    .input-group__input {
      padding-right: 30px;
      @media #{$media-md-up} {
        padding-right: 200px;
      }
    }
    .input-group__button {
      width: auto;
      padding-top: 6px;
      font-size: 14px;
      font-weight: 600;
      font-style: normal;
      font-stretch: normal;
      letter-spacing: -0.2300000041723251px;
      color: $font-color-body;
      cursor: pointer;
    }
  }
  &--password {
    .input-group__button {
      width: auto;
      font-size: 16px;
      font-weight: 600;
      color: $input-password-color;
      &:hover,
      &:focus {
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }
  &--error {
    .input-group__helper {
      color: $input-error;
    }
    .input {
      background-color: $input-error-alt;
      border-color: $input-error;
    }
    .radio-group {
      background-color: $input-error-alt;

      .radio__label {
        &:before {
          background-color: $input-error-alt;
          border-color: $input-error;
        }
      }
    }
    .checkbox {
      background-color: $input-error-alt;
    }
    .text-area {
      background-color: $input-error-alt;
      border-color: $input-error;
    }
  }
  &--alone {
    margin-bottom: 0;
  }
  &__content {
    position: relative;
    margin-left: 50px;
    margin-bottom: 20px;
    border-left: 4px solid $input-border-color;
    padding-left: 25px;
    padding-bottom: 20px;
    padding-top: 13px;

    .select {
      margin-top: 0;
    }

    &--no-indent {
      margin-left: 0;
    }
  }
}