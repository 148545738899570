.rep-info {
  margin-top: 20px;
  margin-bottom: 0;
  padding: 0;
  list-style: none;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  @media #{$media-md-up} {
    flex-direction: row;
  }
  &__item {
    margin: 0;
    padding-bottom: 0px;
    &:last-child {
      padding-bottom: 0;
    }
    @media #{$media-md-up} {
      padding-bottom: 0;
      padding-left: 15px;
      padding-right: 15px;
      border-left: 1px solid $color-text;
      &:first-child {
        padding-left: 0;
        border-left: none;
      }
      &:last-child {
        padding-right: 0;
      }
    }
  }
  &__icon {
    display: block;
    margin: 0 auto 10px auto;
    @media #{$media-md-up} {
      display: inline-block;
      margin-bottom: 0;
      margin-right: 14px;
    }
  }
}