.video {
  position: relative;
  width: 100%;
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.3);
  /*******START: TEMPORARY PLACEHOLDER*******/
  &--placeholder {
    padding-bottom: 56.9%;
    background-color: $video-color;
    &:after {
      content: "Video Placeholder";
      position: absolute;
      top: 50%;
      left: 50%;
      color: $video-font-color;
      transform: translate(-50%,-50%);
    }
  }
  /*******END: TEMPORARY PLACEHOLDER*******/

}