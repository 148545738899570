.overview-info {
  .text-sm {
    font-size: 13px;
  }
  .heading {
    padding-bottom: 0;
  }
  @media #{$media-sm-down} {
    display: grid;
    grid-template-columns: 70px 1fr;
    grid-column-gap: 10px;
    text-align: right;
    div:first-child {
      grid-row-start: 1;
      grid-row-end: 4;
      text-align: left;
    }
  }
}