.icon {
  position: relative;
  display: inline-block;
  flex-shrink: 0;
  background-repeat: no-repeat;
  background-position: center center;
  background-color: transparent;
  background-size: contain;
  vertical-align: middle;

  &--block {
    margin-bottom: 20px;
  }
  &--cta {
    display: block;
    margin-bottom: 20px;
    @media #{$media-md-up} {
      display: inline-block;
      margin-bottom: 0;
    }
  }
  &--alert {
    width: 48px;
    height: 48px;
    background-image: url(/images/global/icons/alert.svg);
  }
  &--autopay {
    width: 30px;
    height: 30px;
    background-image: url(/images/global/icons/autopay.svg);
    @media #{$media-md-up} {
      width: 48px;
      height: 48px;
    }
  }
  &--bank {
    width: 48px;
    height: 48px;
    background-image: url(/images/global/icons/bank.svg);
  }
  &--bank-alt {
    width: 48px;
    height: 48px;
    background-image: url(/images/global/icons/bank-grey.svg);
  }
  &--checkmark {
    width: 20px;
    height: 20px;
    background-image: url('/images/global/icons/check.svg');
  }
  &--close {
    width: 30px;
    height: 30px;
    background-image: url(/images/global/icons/close.svg);
  }
  &--computer {
    width: 48px;
    height: 48px;
    background-image: url(/images/global/icons/computer.svg);
  }
  &--document {
    width: 48px;
    height: 48px;
    background-image: url(../images/global/icons/documents.svg);
  }
  &--dollar {
    height: 20px;
    width: 20px;
    background-image: url(/images/global/icons/dollar.svg);
  }
  &--download {
    width: 48px;
    height: 48px;
    background-image: url(/images/global/icons/download.svg);
  }
  &--escrow {
    width: 48px;
    height: 48px;
    background-image: url(/images/global/icons/escrow.svg);
  }
  &--email {
    width: 48px;
    height: 48px;
    background-image: url(/images/global/icons/email.svg);
  }
  &--expand {
    width: 30px;
    height: 30px;
    background-image: url(/images/global/icons/expand.svg);
  }
  &--external-link {
    height: 28px;
    width: 28px;
    background-image: url(/images/global/icons/external-link.svg);
  }
  &--messages {
    position: relative;
    width: 20px;
    height: 20px;
    background-image: url(/images/global/icons/messages.svg);
    background-size: 20px 14px;
    &--unread {
      width: 34px;
      padding-right: 7px;
      padding-left: 7px;
      &:after {
        content: " ";
        position: absolute;
        bottom: -3px;
        right: 0;
        width: 14px;
        height: 14px;
        background-color: $icon-color-unread;
        border-radius: 50%;
        border: 2px solid $icon-color-unread-border;
      }
    }
  }
  &--messages-ko {
    width: 20px;
    height: 20px;
    background-image: url(/images/global/icons/messages-ko.svg);
  }
  &--question {
    width: 48px;
    height: 48px;
    background-image: url(/images/global/icons/question.svg);
  }
  &--form-check {
    width: 48px;
    height: 48px;
    background-image: url(../images/global/icons/form-check.svg);
  }
  &--hardship {
    width: 48px;
    height: 48px;
    background-image: url(../images/global/icons/hardship.svg);
  }
  &--heart {
    width: 48px;
    height: 48px;
    background-image: url(/images/global/icons/heart.svg);
  }
  &--headset {
    width: 48px;
    height: 48px;
    background-image: url(/images/global/icons/headset.svg);
  }
  &--honor {
    width: 48px;
    height: 48px;
    background-image: url(../images/global/icons/honor.svg);
  }
  &--left-arrow {
    width: 48px;
    height: 48px;
    background-image: url(/images/global/icons/arrow-right.svg);
    transform: rotate(180deg);
  }
  &--right-arrow {
    width: 48px;
    height: 48px;
    background-image: url(/images/global/icons/arrow-right.svg);
  }
  &--howto {
    width: 48px;
    height: 48px;
    background-image: url(../images/global/icons/howto.svg);
  }
  &--information {
    width: 48px;
    height: 48px;
    background-image: url(../images/global/icons/information.svg);
  }
  &--lock {
    width: 13px;
    height: 13px;
    background-image: url(/images/global/icons/lock.svg);
  }
  &--lang {
    width: 12px;
    height: 12px;
    background-image: url(/images/global/icons/lang.svg);
  }
  &--pdf {
    width: 24px;
    height: 24px;
    background-image: url(/images/global/icons/file-pdf-sm.png);
    @media #{$media-md-up} {
      width: 32px;
      height: 32px;
      background-image: url(/images/global/icons/file-pdf.png);
    }
  }
  &--pdf-sm {
    @media #{$media-md-up} {
      width: 24px;
      height: 24px;
      background-image: url(/images/global/icons/file-pdf-sm.png);
    }
  }
  &--print {
    width: 30px;
    height: 30px;
    background-image: url(/images/global/icons/print.svg);
  }
  &--attachment {
    width: 20px;
    height: 20px;
    background-image: url(/images/global/icons/attachment.svg);
  }
  &--profile {
    width: 20px;
    height: 20px;
    background-image: url(/images/global/icons/profile.svg);
  }
  &--close {
    width: 30px;
    height: 30px;
    background-image: url(/images/global/icons/close.svg);
  }
  &--close-alt {
    height: 48px;
    width: 48px;
    background-image: url(/images/global/icons/close-alt.svg);
  }
  &--num-1 {
    height: 48px;
    width: 48px;
    background-image: url(/images/global/icons/num-1.svg);
  }
  &--num-2 {
    height: 48px;
    width: 48px;
    background-image: url(/images/global/icons/num-2.svg);
  }
  &--num-3 {
    height: 48px;
    width: 48px;
    background-image: url(/images/global/icons/num-3.svg);
  }
  &--phone {
    width: 28px;
    height: 48px;
    background-image: url(/images/global/icons/phone.svg);
  }
  &--question {
    width: 48px;
    height: 48px;
    background-image: url(../images/global/icons/question.svg);
  }
  &--resources {
    width: 48px;
    height: 48px;
    background-image: url(../images/global/icons/resources.svg);
  }
  &--search {
    width: 30px;
    height: 30px;
    background-image: url(/images/global/icons/search.svg);
  }
  &--search-ko {
    width: 40px;
    height: 40px;
    background-image: url(/images/global/icons/search-alt.svg);
  }
  &--tooltip {
    width: 20px;
    height: 20px;
    background-image: url(/images/global/icons/tooltip.svg);
  }
  &--paperless {
    width: 30px;
    height: 30px;
    background-image: url(/images/global/icons/paperless.svg);
    @media #{$media-md-up} {
      width: 48px;
      height: 48px;
    }
  }
  &--clock {
    width: 30px;
    height: 30px;
    background-image: url(/images/global/icons/clock.svg);
    @media #{$media-md-up} {
      width: 48px;
      height: 48px;
    }
  }
  &--rep {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    background-color: $color-gandalf;
  }
  &--calendar {
    width: 20px;
    height: 20px;
    background-image: url(/images/global/icons/calendar.svg);
  }
  &--with-text {
    top: -1px;
    margin-right: 6px;
  }
  &--sm {
    width: 30px;
    height: 30px;
  }
  &--align-sub {
    vertical-align: sub;
  }
  a & {
    margin-top: -1px;
    margin-right: 10px;
    &--alone {
      margin-right: 0;
    }
  }
}