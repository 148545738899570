/*!
========== CONFIG
*/
// configuration settings, no file should output actual markup
@import '_config/__config';

/*!
========== SPECIFICS
*/
// declarations specific to pages...features generally not reused on other pages
@import 'specifics/__specifics-private';