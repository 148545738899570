.alert {
  position: relative;
  color: $alert-font-color;
  text-align: center;
  line-height: 1.4210526315789473;
  background-color: $alert-color;
  &__content {
    position: relative;
    padding-top: 20px;
    padding-bottom: 20px;
    @media #{$media-md-up} {
      padding-top: 27px;
      padding-bottom: 26px;
    }
  }
  &__actions {
    padding-left: 20px;
    padding-right: 20px;
    text-align: center;
    width: 150px;

    a {
      + a {
        margin-left: 30px;
      }
    }
  }
  &__dismiss {
    position: absolute;
    top: 10px;
    left: 50%;
    padding: 2px 9px;
    font-size: 32px;
    line-height: 1;
    color: $alert-font-color-dismiss;
    border: none;
    border-radius: 50%;
    background: none;
    background-color: $color-white;
    appearance: none;
    transform: translateX(-50%);
    @media #{$media-md-up} {
      top: 50%;
      left: auto;
      right: 0;
      transform: translateY(-50%);
    }
  }
  &[data-dismissable] {
    .alert__content {
      padding-top: 55px;
      @media #{$media-md-up} {
        padding-top: 23px;
        padding-bottom: 23px;
        padding-left: 50px;
        padding-right: 50px;
      }
    }
  }
  a {
    color: inherit;
    white-space: nowrap;
  }
  &--announcement {
    background-color: $alert-color-announcement;
    color: $alert-font-color-announcement;
    .alert__dismiss {
      top: 0;
      left: auto;
      right: -9px;
      transform: none;
    }
    &[data-dismissable] {
      .alert__content {
        padding-top: 35px;
        @media #{$media-md-up} {
           padding-top: 23px;
        }
      }
    }
  }
  &--callout {
    background-color: $alert-color-callout;
    color: $alert-font-color-callout;
  }
}