.select {
  position: relative;
  margin-top: 14px;
  background: transparent;
  //appearance: none;
  &:focus { outline: 1px dotted $select-focus-color; }
  &:after {
    content: " ";
    position: absolute;
    top: 40%;
    right: 5px;
    display: inline-block;
    padding: 4px;
    border: solid $select-arrow-color;
    border-width: 0 2px 2px 0;
    transform: translateY(-64%) rotate(45deg);
    pointer-events: none;
  }
  select::-ms-expand {
    display: none;
  }
  select {
    -moz-appearance: none;
    background: transparent;
  }
}