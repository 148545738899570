.tracker {
  position: relative;
  display: flex;
  margin: 10px 0 0 0;
  padding-left: 22px;
  margin-right: 13px;
  justify-content: space-between;
  align-items: center;
  list-style: none;
  @media #{$media-md-up} {
    padding-left: 29px;
    margin-top: 14px;
    margin-right: 30px
  }
  @media #{$media-lg-up} {
    padding-left: 53px;
    margin-top: 50px;
    margin-right: 53px;
  }
  &:before {
    content: '';
    position: absolute;
    top: 33px;
    left: 0;
    width: 100%;
    height: 3px;
    font-size: 19px;
    background-color: $color-greek-ocean;
    z-index: 1;
  }
  &__item {
    position: relative;
    display: flex;
    height: 116px;
    margin-top: 0;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    z-index: 1; 
    &:first-child,
    &:nth-child(2) {
      display: none;
    }
    @media only screen and (min-width: 620px) {
      &:nth-child(2) {
        display: flex;
      }
    }
    @media #{$media-md-up} {
      &:first-child {
        display: flex;
      }
    }
    &--inert {
      .tracker__marker {
        // border-color: $color-gandalf-gray;
        &:before {
          content: '—';
          border-radius: 50%;
          text-align: center;
          color: white;
          background-color: $color-greek-ocean;
        }
      }
    }
    &--good {
      .tracker__marker:before {
        background-image: url('/images/global/icons/check.svg');
      }
    }
    &--partial {
      .tracker__marker:before {
        background-image: url('/images/global/icons/partial.svg');
      }
    }
    &--scheduled {
      .tracker__marker:before {
        background-image: url('/images/global/icons/clock.svg');
      }
    }
    &--alert {
      .tracker__marker:before {
        background-image: url('/images/global/icons/alert-grey.svg');
      }
    }
    &--missed {
      .tracker__marker:before {
        background-image: url('/images/global/icons/alert-x-red.svg');
      }
    }
    &--lg {
      .tracker__marker {
        width: 66px;
        height: 66px;
        margin-top: 0;
        &:before {
          width: 48px;
          height: 48px;
        }
      }
    }
  }
  &__marker {
    position: relative;
    height: 48px;
    width: 48px;
    margin-top: 8px;
    margin-bottom: 10px;
    margin-left: auto;
    margin-right: auto;
    border-radius: 50%;
    border: 3px solid $color-greek-ocean;
    background-color: $color-white;
    &:before {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      width: 30px;
      height: 30px;
      background: transparent url() no-repeat center center;
      background-size: contain;
      transform: translate(-50%, -50%);
    }
  }
  &__text {
    font-size: $font-size-sm;
    line-height: $font-size-alt;
    font-weight: $font-weight-bold;
    text-align: center;
  }
  &--repayment {
    // padding-left: 0;
    // padding-right: 0;
  }
}