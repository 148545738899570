.radio-group {
  display: flex;
  //justify-content: space-between;

  .radio + .radio {
    margin-left: 50px;
  }

  &--stacked {
    flex-direction: column;

    .radio {
      margin-bottom: 20px;
      &:last-child {
        margin-bottom: 0;
      }

      + .radio {
        margin-left: 0;
      }
    }
  }
}