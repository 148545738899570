@font-face {
  font-family: 'Merriweather';
  font-style: normal;
  font-weight: 400;
  src: local('Merriweather'), url(/fonts/Merriweather/Merriweather-Regular.ttf) format('truetype');
}
@font-face {
  font-family: 'Merriweather';
  font-style: italic;
  font-weight: 400;
  src: local('Merriweather Italic'), url(/fonts/Merriweather/Merriweather-Italic.ttf) format('truetype');
}
@font-face {
  font-family: 'Merriweather';
  font-style: normal;
  font-weight: 700;
  src: local('Merriweather Bold'), url(/fonts/Merriweather/Merriweather-Bold.ttf) format('truetype');
}
@font-face {
  font-family: 'Merriweather';
  font-style: italic;
  font-weight: 700;
  src: local('Merriweather Bold Italic'), url(/fonts/Merriweather/Merriweather-BoldItalic.ttf) format('truetype');
}
@font-face {
  font-family: 'Merriweather';
  font-style: normal;
  font-weight: 900;
  src: local('Merriweather Black'), url(/fonts/Merriweather/Merriweather-Black.ttf) format('truetype');
}
@font-face {
  font-family: 'Merriweather';
  font-style: italic;
  font-weight: 900;
  src: local('Merriweather Black Italic'), url(/fonts/Merriweather/Merriweather-BlackItalic.ttf) format('truetype');
}

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: local('Open Sans'), url(/fonts/Open_Sans/OpenSans-Regular.ttf) format('truetype');
}
@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 400;
  src: local('Open Sans Italic'), url(/fonts/Open_Sans/OpenSans-Italic.ttf) format('truetype');
}
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  src: local('Open Sans SemiBold'), url(/fonts/Open_Sans/OpenSans-SemiBold.ttf) format('truetype');
}
@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 600;
  src: local('Open Sans SemiBold Italic'), url(/fonts/Open_Sans/OpenSans-SemiBoldItalic.ttf) format('truetype');
}
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  src: local('Open Sans Bold'), url(/fonts/Open_Sans/OpenSans-Bold.ttf) format('truetype');
}
@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 700;
  src: local('Open Sans Bold Italic'), url(/fonts/Open_Sans/OpenSans-BoldItalic.ttf) format('truetype');
}
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 800;
  src: local('Open Sans ExtraBold'), url(/fonts/Open_Sans/OpenSans-ExtraBold.ttf) format('truetype');
}
@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 800;
  src: local('Open Sans ExtraBold Italic'), url(/fonts/Open_Sans/OpenSans-ExtraBoldItalic.ttf) format('truetype');
}